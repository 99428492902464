export class MessageRequest{
    function:string;
    service:string;
    token:string;
}

export class MessageReply{
    status:number;
    description:string;
    id:string;
    service:string;
    version:string;
    function:string;
    userstring:string;
    localreplyaddress:string;
    token:string;
}