import { MessageReply, MessageRequest } from '../messages';

//preStageAction message
export class RequestPreStageAction extends MessageRequest {

    function = "PreStageAction";
    service = "Authentication";
    data: RequestPreStageAction.Data = new RequestPreStageAction.Data();
}

export namespace RequestPreStageAction {
    export class Data {
        userId: number;
        stage:number;
    }
}



export class ReplyPreStageAction extends MessageReply {
    data: ReplyPreStageAction.Data;
}

export namespace ReplyPreStageAction {
    export class Data {
        result: number;
        fields: Map<string, string>;
    }
}


export class RequestAuthenticateUser extends MessageRequest {

    function = "authenticateUser";
    service = "Authentication";
    data: RequestAuthenticateUser.Data = new RequestAuthenticateUser.Data();
}

export namespace RequestAuthenticateUser {
    export class Data {
        auth_inst_id: number;
        user_fields: Array<FieldNameValue>;
        os: string;
        browser: string;
        stage:number;
        master_user_id:number;
        thirdPartyToken:string;
        sessionId: string;
    }
}
export class FieldNameValue {
    name: string;
    value: string;
}

export class ReplyAuthenticateUser extends MessageReply {
    data: ReplyAuthenticateUser.Data;
}

export namespace ReplyAuthenticateUser {
    export class Data {
        authenticated: boolean;
        userid: number;
        userToken: string;
        thirdPartyToken: string;
        master_user_id:number;
        nextStage:number;
        nextUserPrevCredentials:boolean;
        nextPrompts:Array<AuthenticationPromptField>;
        sessionId: string;

        showDescription: boolean;
        description: string;
    }
}

// GetDefaultAuhtInst
export class RequestGetDefaultAuthInst extends MessageRequest {
    function = "getDefaultAuthInst";
    service = "Authentication";
}

export class ReplyGetDefaultAuthInst extends MessageReply {
    data: ReplyGetDefaultAuthInst.Data;

}

export namespace ReplyGetDefaultAuthInst {
    export class Data {
        instanceId: number;
    }
}

export class RequestGetDefaultAuthenticationPrompts extends MessageRequest {
    function = "getDefaultAuthPrompts";
    service = "Authentication";
}

export class ReplyGetDefaultAuthenticationPrompts extends MessageReply {
    data: ReplyGetDefaultAuthenticationPrompts.Data;
}

export namespace ReplyGetDefaultAuthenticationPrompts {
    export class Data {
        prompts: Array<AuthenticationPromptField>;
    }
}

export class AuthenticationPromptField {
    auth_prompt_id: number;
    auth_prompt_name: string;
    auth_prompt_label: string;
    auth_prompt_description: string;
    auth_prompt_type: string;
    auth_prompt_order: number;
}

export class RequestLogout extends MessageRequest {
    function = "logout";
    service = "Authentication";
    data: RequestLogout.Data = new RequestLogout.Data();
}

export namespace RequestLogout {
    export class Data {
        userId: number;
    }
}

export class ReplyLogout extends MessageReply {
    data: ReplyLogout.Data;
}
export namespace ReplyLogout {
    export class Data {
        valid: boolean;
    }
}


export class RequestGetAuthenticationStage extends MessageRequest {
    function = "GetAuthenticationStage";
    service = "Authentication";
    data: RequestGetAuthenticationStage.Data = new RequestGetAuthenticationStage.Data();
}

export namespace RequestGetAuthenticationStage {
    export class Data {
        stage: number;
    }
}

export class ReplyGetAuthenticationStage extends MessageReply {
    data: ReplyGetAuthenticationStage.Data;
}
export namespace ReplyGetAuthenticationStage {
    export class Data {
        authInstance: number;
        usePrevCredentials:boolean;
        prompts: Array<AuthenticationPromptField>;
        instanceFields:Map<string,string>;

        showDescription:boolean;
        description:string;

    }
}

export class RequestGetMasterAdminAuthPrompts extends MessageRequest {
    function = "getGetMasterAdminAuthPrompts";
    service = "Authentication";
    data: RequestGetMasterAdminAuthPrompts.Data = new RequestGetMasterAdminAuthPrompts.Data();
}

export namespace RequestGetMasterAdminAuthPrompts {
    export class Data {
        auth_type_id: number;
    }
}

export class ReplyGetMasterAdminAuthPrompts extends MessageReply {
    data: ReplyGetMasterAdminAuthPrompts.Data;
}

export namespace ReplyGetMasterAdminAuthPrompts {
    export class Data {
        prompts: Array<AuthenticationPromptField>;
    }
}

export class RequestDeleteTotpSecretKey extends MessageRequest {
    function = "DeleteTotpSecretKey";
    service = "Authentication";
    data: RequestDeleteTotpSecretKey.Data = new RequestDeleteTotpSecretKey.Data();

}

export namespace RequestDeleteTotpSecretKey {
    export class Data {
        userId: number;
    }
}

export class ReplyDeleteTotpSecretKey extends MessageReply {

}
