import {MessageReply, MessageRequest} from '../messages'

export class RequestFirstTimeAccess extends MessageRequest {  
    function="firstTimeAccess";
    service="Authentication"; 
}

export class ReplyFirstTimeAccess extends MessageReply{
    data:ReplyFirstTimeAccess.Data;
}

export namespace ReplyFirstTimeAccess{
    export class Data {
        accessValue:boolean;
    }
}

export class RequestCreateAdminUser extends MessageRequest {
    function="createAdminUser";
    service="Admin";
    data:RequestCreateAdminUser.Data=new RequestCreateAdminUser.Data();
}

export namespace RequestCreateAdminUser{
    export class Data{
        adminname:string;
        password:string;
    }
}

export class ReplyCreateAdminUser extends MessageReply {

}