import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { RpcService } from './rpc.service';
import { MsalService } from '@azure/msal-angular';
import { RequestFirstTimeAccess, ReplyFirstTimeAccess } from './firsttimeaccess/firsttimeaccess.messages';
import { iif } from 'rxjs';
import { AzureService } from './azure.service';
@Injectable()
export class AuthGuardService implements CanActivate {

  requestFirstTimeAccess: RequestFirstTimeAccess;
  replyFirstTimeAccess: ReplyFirstTimeAccess;

  errorMsg: string;

  accessValue: boolean;

  counter: string;
  constructor(
    private router: Router, private rpcService: RpcService,
    private msalService: MsalService,
    private azureService: AzureService
  ) { }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ): boolean {
    this.requestFirstTimeAccess = new RequestFirstTimeAccess();

    this.rpcService.postRequest(this.requestFirstTimeAccess)
      .subscribe(
        resData => {
          this.replyFirstTimeAccess = resData
          this.accessValue = this.replyFirstTimeAccess.data.accessValue;

          this.counter = localStorage.getItem("counter");

          if (this.msalService.instance.getActiveAccount()) {
            this.router.navigate(['redirect']);
            return false;
          }
          else if (this.accessValue) {
            this.router.navigate(['login']);
            return true;
          } else {
            this.router.navigate(['eula']);
            return false;
          }
          //}
        },
        responseError => {
          this.errorMsg = responseError
        });

    return this.accessValue;
  }

}