<h1 class="mbr-section-title pb-3 mbr-white mbr-bold mbr-fonts-style display-1 align-center" align="center">Virtual
  Monitor</h1>
<div class="mbr-fonts-style display-5">
  To open your Virtual Monitor on the same PC as the Main Monitor, click <a href="/vmonSecond" target="_blank" > here </a> and drag the window to the right hand
  monitor and press F11 to go into full screen. Then, copy the UID using the button on the Virtual Monitor and click the
  ‘Paste and Launch’ button on the Main Monitor. Enjoy your immersive dual monitor experience!



</div>
<div class="mbr-fonts-style display-5" [style.color]="'red'" *ngIf="showSessionRecording">
<br/>
  Session Recording is turned on.  All actions will be recorded.
</div>

<br/>
<div class="mbr-fonts-style display-5">
  If you wish to connect to a Second Monitor, please enter the Screen Access Code below. Otherwise, select 'Single
  Monitor'.
</div>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput maxlength="8" [(ngModel)]="data.uuid">
  </mat-form-field>
  <div class="error-message" *ngIf="replyGetXYFromUID && replyGetXYFromUID.data.width < 0">Invalid uuid!</div>
</div>


<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" mat-button [mat-dialog-close]="data.uuid"
    (click)="onClickSingleMonitor()">Single Monitor Only</button>

  <button mat-raised-button color="primary" mat-button (click)="onClickMultiMonitor()"
    [disabled]="!data.uuid || data.uuid.length === 0">Connect to Second Monitor</button>

  <button mat-raised-button color="primary" mat-button (click)="onClickPasteAndLaunch()">Paste and Launch</button>
</div>
