import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'session-recording-dialog',
    templateUrl: './session-recording-dialog.component.html',
    styleUrls: ['./session-recording-dialog.component.css'],
  })
  export class SessionRecordingDialog {


    constructor(
        public dialogRef: MatDialogRef<String>
    ) {

    }

    onOk() {
        this.dialogRef.close("ok");
    }

    onCancel() {
        this.dialogRef.close("cancel"); 
    }



  }