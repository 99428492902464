import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { TRANSLATIONS, TRANSLATIONS_FORMAT, LOCALE_ID } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LocaleHelper } from './locale/localeHelper'

if (environment.production) {
  enableProdMode();
}



platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    { provide: TRANSLATIONS,
      useFactory: () => {
        let translationfiles : string[] = [];
        translationfiles[LocaleHelper.US_LOCALE] = LocaleHelper.US_TRANSLATION_FILE;
        translationfiles[LocaleHelper.DE_LOCALE] = LocaleHelper.DE_TRANSLATION_FILE;
        translationfiles[LocaleHelper.FR_LOCALE] = LocaleHelper.FR_TRANSLATION_FILE;
        if (!(!!localStorage.getItem('currentLocale')) || (localStorage.getItem('currentLocale') == 'undefined')){
          localStorage.setItem('currentLocale', LocaleHelper.US_LOCALE);
        }
        return translationfiles[localStorage.getItem('currentLocale')];
      }, deps: [LOCALE_ID]
    },
    { provide: TRANSLATIONS_FORMAT, useValue: "xlf" },
    { provide: LOCALE_ID, useValue: "fr"}

  ]
});