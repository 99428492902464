import { Component, Renderer2 } from '@angular/core';
import { Service } from './service';
import { Router } from '@angular/router';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './createadmin.component.html',
  styleUrls: ['./../../login/login.component.css'],
  providers: [Service]
})

export class CreateAdminComponent {

  public createAdmin: createAdmin;

  constructor(private service: Service, private router: Router, private renderer2: Renderer2) {
    this.createAdmin = {
      adminname: '',
      password: '',
      confirmPassword: ''
    }
  }

  errorMsg: string;
  showLoading: boolean;
  successMessage: boolean;
  showErr: boolean;
  data: any;
  progressValue:number = 0;
  public timer:any;

  mouseenter (event) {
    this.renderer2.addClass(event.target, 'mat-elevation-z9')
 }
 
 mouseleave (event) {
   this.renderer2.removeClass(event.target, 'mat-elevation-z9')
 }

 selectLanguage(languageCode: string) {
  localStorage.setItem('currentLocale', languageCode);
  window.location.reload();
}



  onSubmit(value: any): void {
    this.showLoading = true;
    let x = 0;
    this.timer = setInterval(()=>{
      x += 5;
      this.progressValue = x;
      if(this.progressValue >85) {
        
        clearInterval(this.timer);
      }
     
  }, 1500);

    let params: URLSearchParams = new URLSearchParams();
    params.set("adminname", value.adminname);
    params.set("password", value.password);
    let data = params.toString();
    
    this.service.postRequest(data)
      .subscribe(
        response => {  
                         
          this.successMessage = true;
          this.progressValue = 100;
          clearInterval(this.timer);

        //  if (response.status == 200) {
            setTimeout(function () {          
              this.showLoading = false;            
              this.router.navigate(['/login']);
            }.bind(this), 10000);
         // }
        },
        responseError => {
          this.showLoading = false;
          this.showErr = true;
          this.errorMsg = responseError         
        });
  }
}

export interface createAdmin {
  adminname: string;
  password: string;
  confirmPassword: string;
}