
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class Service {
    private _url: string = "CreateAdminUserCreate"
    constructor(private _http: HttpClient) { }

    postRequest(requestParams): any {
        const header = new HttpHeaders({
            'Accept': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        return this._http.post(this._url,requestParams, { headers: header }).pipe(
            catchError(this._errorHandler));
    }

    _errorHandler(error) {
        console.error(error);
        return Observable.throw(error || "Server Error");
    }
}