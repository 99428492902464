import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'no-remote-storage',
    templateUrl: './no-remote-storage.component.html',
    styleUrls: ['./no-remote-storage.component.css'],
  })
  export class NoRemoteStorageDialog {


    constructor(
      @Inject(MAT_DIALOG_DATA) public data: {
        messageFor: string;
      },
        public dialogRef: MatDialogRef<String>
    ) {

    }

    onOk() {
        this.dialogRef.close();
    }


  }