<!DOCTYPE html>
<html >
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1">
  <title>First time access page</title>
</head>
<body>

<section class="header12 cid-qLo1pqM1N4 mbr-fullscreen" id="header12-i">

    <div class="container">
            <div class="media-container">
                <div class="col-md-12 align-center">
                    <h1 class="mbr-section-title pb-3 mbr-white mbr-bold mbr-fonts-style display-1">Thank you for using Northbridge Secure Systems products</h1>
                    <p class="mbr-text pb-3 mbr-white mbr-fonts-style display-5">Your installation is almost complete<br> To finalise your installation, please click the "Continue" button below:
<br></p>
                    <div class="mbr-section-btn align-center py-2"><button class="btn btn-md btn-primary display-4" (click)="goToEula()">CONTINUE</button></div>

                    <div class="icons-media-container mbr-white">
                        <div class="card col-12 col-md-6 col-lg-3">
                            <div class="icon-block">
                                <span class="mbr-iconfont mbri-sad-face"></span>
                            </div>
                            <h5 class="mbr-fonts-style display-5">Note that until configuration is complete, this page will likely display a certificate error</h5>
                        </div>

                        <div class="card col-12 col-md-6 col-lg-3">
                            <div class="icon-block">
                                <span class="mbr-iconfont mbri-smile-face"></span>
                            </div>
                            <h5 class="mbr-fonts-style display-5">This is expected and will be resolved as soon as you install a certificate, which is part of the configuration</h5>
                        </div>

                        <div class="card col-12 col-md-6 col-lg-3">
                            <div class="icon-block">                            
                                <span class="mbr-iconfont mbri-pages"></span>                               
                            </div>
                            <h5 class="mbr-fonts-style display-5">To help you with configuration, the configuration manual is available <a href="#" target="_blank">here</a></h5>
                        </div>

                        <div class="card col-12 col-md-6 col-lg-3">
                            <div class="icon-block">
                                <span class="mbr-iconfont mbri-help"></span>                                
                            </div>
                            <h5 class="mbr-fonts-style display-5">If you require any assistance feel free to  contact our <a href="mailto:customersupport@northbridgesecure.com">Support Team</a></h5>
                        </div>
                    </div><br>
                    <p class="mbr-text pb-3 mbr-white mbr-fonts-style display-5">Again, <span class="imp-text">thank you</span> for using our products<br>- The <a href="https://www.northbridgesecure.com"target="_blank">Northbridge Secure</a> Team</p>
                </div>
            </div>
    </div>
</section>
  
</body>
</html>