import { RdpLaunchDialogComponent } from './rdp-launch-dialog/rdp-launch-dialog.component';
import { SessionRecordingDialog } from './rdp-launch-dialog/session-recording-dialog.component';
import { NoRemoteStorageDialog } from './rdp-launch-dialog/no-remote-storage.component';
import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { RpcService } from '../rpc.service';
import { Router } from '@angular/router';
import { NextGenApplication, AppInstanceHeading, AppTypeField, AppTypeOption } from './workspace.models';
import { LocaleHelper } from '../../locale/localeHelper';
//import { Observable } from 'rxjs/Observable';
import { interval, Subscription } from 'rxjs';
import {
  ReplyGetUserInfo, RequestGetUserInfo,
  ReplyGetAppInstanceFieldsAndValues, RequestGetAppInstanceFieldsAndValues,
  ReplyGetUserApps, RequestGetUserApps,
  RequestLogout, ReplyLogout,
  RequestCheckTokenTimeout, ReplyCheckTokenTimeout,
  RequestCheckTokenActivity, ReplyCheckTokenActivity,
  RequestValidateLicenseForApp, ReplyValidateLicenseForApp,
  RequestReturnConcurrentLicense, ReplyReturnConcurrentLicense,
  RequestGetExternalStorageSettings, ReplyGetExternalStorageSettings
} from './workspaceMessages';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-root',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css'],
  providers: [RpcService]

})
export class WorkspaceComponent implements OnInit {
  errorMsg: string;
  currentLocale: string;
  languageImages: string[] = [];
  displayNoAppsError: boolean = false;
  //Boolean for detecting if the refresh is from
  refreshFromLanguageChange: boolean = false;
  uuid: string;
  vmonParam = '?vmonCode=';
  showVmonPrompt = 1;
  showSessionRecordingPrompt = true;

  appTypeField: AppTypeField[];
  sessionRecordingField: AppTypeField[];
  sessionRecordingStorageType: string;

  hyperdriveField: AppTypeField[];
  hyperdriveStorageType: string;

  //isLoading: boolean = false;


  //UserApps vars
  apps: Array<NextGenApplication> = [];
  // apps: Array<NextGenApplication> = [{instanceId:0,
  //   instanceType:0,
  //   appTypeName:"Test App",
  //   instanceName:"testappstring",
  //   instanceImage:"testimagestring",
  //   accessType:"testaccesstypestring",
  //   accessString:"testaccessstringstring"},{instanceId:0,
  //     instanceType:0,
  //     appTypeName:"Test App",
  //     instanceName:"testappstring",
  //     instanceImage:"testimagestring",
  //     accessType:"testaccesstypestring",
  //     accessString:"testaccessstringstring"},{instanceId:0,
  //       instanceType:0,
  //       appTypeName:"Test App",
  //       instanceName:"testappstring",
  //       instanceImage:"testimagestring",
  //       accessType:"testaccesstypestring",
  //       accessString:"testaccessstringstring"},{instanceId:0,
  //         instanceType:0,
  //         appTypeName:"Test App",
  //         instanceName:"testappstring",
  //         instanceImage:"testimagestring",
  //         accessType:"testaccesstypestring",
  //         accessString:"testaccessstringstring"},{instanceId:0,
  //           instanceType:0,
  //           appTypeName:"Test App",
  //           instanceName:"testappstring",
  //           instanceImage:"testimagestring",
  //           accessType:"testaccesstypestring",
  //           accessString:"testaccessstringstring"},{instanceId:0,
  //             instanceType:0,
  //             appTypeName:"Test App",
  //             instanceName:"testappstring",
  //             instanceImage:"testimagestring",
  //             accessType:"testaccesstypestring",
  //             accessString:"testaccessstringstring"},{instanceId:0,
  //               instanceType:0,
  //               appTypeName:"Test App",
  //               instanceName:"testappstring",
  //               instanceImage:"testimagestring",
  //               accessType:"testaccesstypestring",
  //               accessString:"testaccessstringstring"},{instanceId:0,
  //                 instanceType:0,
  //                 appTypeName:"Test App",
  //                 instanceName:"testappstring",
  //                 instanceImage:"testimagestring",
  //                 accessType:"testaccesstypestring",
  //                 accessString:"testaccessstringstring"},{instanceId:0,
  //                   instanceType:0,
  //                   appTypeName:"Test App",
  //                   instanceName:"testappstring",
  //                   instanceImage:"testimagestring",
  //                   accessType:"testaccesstypestring",
  //                   accessString:"testaccessstringstring"},{instanceId:0,
  //                     instanceType:0,
  //                     appTypeName:"Test App",
  //                     instanceName:"testappstring",
  //                     instanceImage:"testimagestring",
  //                     accessType:"testaccesstypestring",
  //                     accessString:"testaccessstringstring"},{instanceId:0,
  //                       instanceType:0,
  //                       appTypeName:"Test App",
  //                       instanceName:"testappstring",
  //                       instanceImage:"testimagestring",
  //                       accessType:"testaccesstypestring",
  //                       accessString:"testaccessstringstring"},{instanceId:0,
  //                         instanceType:0,
  //                         appTypeName:"Test App",
  //                         instanceName:"testappstring",
  //                         instanceImage:"testimagestring",
  //                         accessType:"testaccesstypestring",
  //                         accessString:"testaccessstringstring"}];

  openWindows: Array<Window> = [];

  //Admin App
  adminApplication: NextGenApplication;

  //Displays username
  currentUsername: string;
  currentUserId: number;

  //GetUserInfo
  requestGetUserInfo: RequestGetUserInfo;
  replyGetUserInfo: ReplyGetUserInfo;

  //GetUserApps
  requestGetUserApps: RequestGetUserApps;
  replyGetUserApps: ReplyGetUserApps;

  //CheckTokenTimeout
  requestCheckTokenTimeout: RequestCheckTokenTimeout;
  replyCheckTokenTimeout: ReplyCheckTokenTimeout;

  //CheckTokenTimeout
  requestCheckTokenActivity: RequestCheckTokenActivity;
  replyCheckTokenActivity: ReplyCheckTokenActivity;

  //CheckAndGetConcurrentLicense
  requestValidateLicenseForApp: RequestValidateLicenseForApp;
  replyValidateLicenseForApp: ReplyValidateLicenseForApp;

  //ReturnConcurrentLicense
  requestReturnConcurrentLicense: RequestReturnConcurrentLicense;
  replyReturnConcurrentLicense: ReplyReturnConcurrentLicense;

  requestlogout: RequestLogout;
  replyLogout: ReplyLogout;

  //RequestGetAppInstanceFieldsAndValues
  requestGetAppInstanceFieldsAndValues: RequestGetAppInstanceFieldsAndValues;
  replyGetAppInstanceFieldsAndValues: ReplyGetAppInstanceFieldsAndValues;

  tokenTimeoutSubscription: Subscription;
  tokenActivitySubscription: Subscription;


  constructor(private rpcService: RpcService,
    private router: Router, private renderer2: Renderer2, private authService: MsalService,
    public RdpLaunchDialog: MatDialog) {
    this.languageImages[LocaleHelper.US_LOCALE] = LocaleHelper.US_IMAGE_ICON;
    this.languageImages[LocaleHelper.DE_LOCALE] = LocaleHelper.DE_IMAGE_ICON;
    this.languageImages[LocaleHelper.FR_LOCALE] = LocaleHelper.FR_IMAGE_ICON;
    this.currentUsername = "";
    this.requestGetUserInfo = new RequestGetUserInfo();
    this.requestGetUserApps = new RequestGetUserApps();
    this.requestlogout = new RequestLogout();
    this.requestCheckTokenTimeout = new RequestCheckTokenTimeout();
    this.requestCheckTokenActivity = new RequestCheckTokenActivity();
    this.requestValidateLicenseForApp = new RequestValidateLicenseForApp();
    this.requestReturnConcurrentLicense = new RequestReturnConcurrentLicense();
    this.requestGetAppInstanceFieldsAndValues = new RequestGetAppInstanceFieldsAndValues();
  }

  ngOnInit() {

    this.adminApplication = undefined;
    //this.isLoading = false;

    this.refreshFromLanguageChange = false;
    if (this.isTokenCorrupt()) {
      this.logout();
      return;
    } 


    if (this.isCurrentLocaleCorrupt()) {
      localStorage.setItem('currentLocale', LocaleHelper.US_LOCALE);
      this.currentLocale = LocaleHelper.US_LOCALE;
    }

    this.rpcService.postRequest(this.requestGetUserInfo)
      .subscribe(
        resGetUserInfo => {
          this.replyGetUserInfo = resGetUserInfo;
          if (Number(this.replyGetUserInfo.status) < 0) {
            this.router.navigate(['/login'])
            return;
          }
          this.currentUsername = this.replyGetUserInfo.data.user.user_heading.user_name;
          this.currentUserId = this.replyGetUserInfo.data.user.user_heading.user_id;
          localStorage.setItem('username', this.currentUsername);
        }),
      responseGetUserInfo => {
        this.errorMsg = responseGetUserInfo
      };


    this.rpcService.postRequest(this.requestGetUserApps)
      .subscribe(
        resGetUserApps => {
          this.replyGetUserApps = resGetUserApps;
          if (Number(this.replyGetUserApps.status) < 0) {
            this.router.navigate(['/login'])
            return;
          }
          for (let key in this.replyGetUserApps.data.appInfo) {
            let app = new NextGenApplication(
              this.replyGetUserApps.data.appInfo[key].app_instance_id,
              this.replyGetUserApps.data.appInfo[key].app_type_id,
              this.replyGetUserApps.data.appInfo[key].app_type_name,
              this.replyGetUserApps.data.appInfo[key].app_instance_name,
              this.replyGetUserApps.data.appInfo[key].app_instance_image,
              this.replyGetUserApps.data.accessInfo[key].app_access_type,
              this.replyGetUserApps.data.accessInfo[key].app_access_string,false);

            //Put App to specific array
            switch (app.appTypeName) {
              case "remoteApplication":
                // this.stdApps.push(app);
                this.apps.push(app)
                break;
              case "lyf":
                this.apps.push(app);
                break;
              case "Admin":
                this.apps.push(app)
                this.adminApplication = app;
                break;
              default: this.apps.push(app);
            }
          }

          if (this.apps.length == 0) {
            this.displayNoAppsError = true;
          }
        }),
      responseGetUserApps => {
        this.errorMsg = responseGetUserApps;
      };

    const source = interval(30000);

    this.tokenTimeoutSubscription = source.subscribe((val) => { this.checkTokenTimeout(); })
    this.tokenActivitySubscription = source.subscribe((val) => { this.checkTokenActivity(); })
  }

  mouseenter(event) {
    this.renderer2.addClass(event.target, 'mat-elevation-z5')
  }

  mouseleave(event) {
    this.renderer2.removeClass(event.target, 'mat-elevation-z5')
  }

  accessAppInstance(app: NextGenApplication) {
    //
    this.requestGetAppInstanceFieldsAndValues.data.app_type_id = app.instanceType;
    this.requestGetAppInstanceFieldsAndValues.data.app_instance_id = app.instanceId;
    this.sessionRecordingStorageType = '0';

    //this.isLoading = true;
    app.status = true;

    this.rpcService.postRequest(this.requestGetAppInstanceFieldsAndValues)
      .subscribe(
        resGetAppInstanceFieldsAndValues => {
          this.replyGetAppInstanceFieldsAndValues = resGetAppInstanceFieldsAndValues;
          this.appTypeField = this.replyGetAppInstanceFieldsAndValues.data.app_type_fields.
            filter(x => x.app_type_field_name === 'vmon_prompt');
            
          this.sessionRecordingField = this.replyGetAppInstanceFieldsAndValues.data.app_type_fields.
            filter(x => x.app_type_field_name === 'sessionRecording');

          this.hyperdriveField = this.replyGetAppInstanceFieldsAndValues.data.app_type_fields.
                filter(x => x.app_type_field_name === 'virtualdriveupload');

          if (this.appTypeField.length > 0) {
            //this.showVmonPrompt = (this.appTypeField[0].app_type_field_default_value) === 'true';
            this.showVmonPrompt = Number(this.appTypeField[0].app_type_field_default_value);
            console.log("showVmonPrompt: " + this.showVmonPrompt.toString()   + " " + this.appTypeField[0].app_type_field_default_value);
          }

          if (this.sessionRecordingField.length > 0) {

            this.showSessionRecordingPrompt = (this.sessionRecordingField[0].app_type_field_default_value) != '0';
            this.sessionRecordingStorageType = this.sessionRecordingField[0].app_type_field_default_value;


          }

          if (this.sessionRecordingStorageType == '2') {

            let request: RequestGetExternalStorageSettings = new RequestGetExternalStorageSettings();
            let reply: ReplyGetExternalStorageSettings

            request.data.type = "SESSIONRECORDING";


            this.rpcService.postRequest(request)
              .subscribe(resReply => {
                reply = resReply;

                if ((reply.data.info == undefined) || (reply.data.info.length == 0) ||
                  (reply.data.info[0].mounted == false)) {


                  const noRemoteStorageDialogRef = this.RdpLaunchDialog.open(NoRemoteStorageDialog, {data: { messageFor: "Session Recording"}});
                  noRemoteStorageDialogRef.afterClosed()
                    .subscribe(selection => {
                     // this.isLoading = false;
                      app.status = false;
                      return;
                    });

                } else {
                  if ((reply.data.info != undefined) || (reply.data.info.length > 0) ||
                    (reply.data.info[0].mounted == true)) {
                    //this.accessAppInstanceStage2(app);
                    this.checkHyperdriveMount(app);
                  }
                }

              });

          } else {
            //this.accessAppInstanceStage2(app);
            this.checkHyperdriveMount(app);
          }
        });

        //this.isLoading = false;
  }



  checkHyperdriveMount(app: NextGenApplication) {

    this.hyperdriveStorageType = "Off"; 

    if (this.hyperdriveField.length > 0) {
      this.hyperdriveStorageType = this.hyperdriveField[0].app_type_field_default_value;
    }

    if (this.hyperdriveStorageType == 'OnRemote') {

      let request: RequestGetExternalStorageSettings = new RequestGetExternalStorageSettings();
      let reply: ReplyGetExternalStorageSettings

      request.data.type = "HYPERDRIVE";


      this.rpcService.postRequest(request)
        .subscribe(resReply => {
          reply = resReply;

          if ((reply.data.info == undefined) || (reply.data.info.length == 0) ||
            (reply.data.info[0].mounted == false)) {


            const noRemoteStorageDialogRef = this.RdpLaunchDialog.open(NoRemoteStorageDialog, {data: { messageFor: "Hyperdrive"}});
            noRemoteStorageDialogRef.afterClosed()
              .subscribe(selection => {
               // this.isLoading = false;
                app.status = false;
                return;
              });

          } else {
            if ((reply.data.info != undefined) || (reply.data.info.length > 0) ||
              (reply.data.info[0].mounted == true)) {
              this.accessAppInstanceStage2(app);
            }
          }

        });

    } else {
      this.accessAppInstanceStage2(app);
      
    }

  }



  accessAppInstanceStage2(app: NextGenApplication) {


    //this.isLoading = false;
    app.status = false;
    // if its is admin app or show prompt setting is false, do not open the dialog

    //commented from conflict - head
    //if (app.appTypeName != "remoteApplication" || (!this.showVmonPrompt && !this.showSessionRecordingPrompt)) {
    //use from conflict autoVMPrototype
    //if (app.instanceType !== 1 || ((this.showVmonPrompt == 0) && !this.showSessionRecordingPrompt)) {
    if (app.appTypeName != "remoteApplication" || ((this.showVmonPrompt == 0) && !this.showSessionRecordingPrompt)) {
      this.LaunchRdpApp(app);
      return;
    } else if (this.showVmonPrompt > 0) {


      let cookieUUID: string = this.getCookie(app.instanceId.toString() + "_vmon_uuid");

      if ((cookieUUID != null) && (this.showVmonPrompt == 2)) {

        const originalPath = app.accessString.split('?', 1);
        app.accessString = originalPath[0] + this.vmonParam + cookieUUID;
        this.LaunchRdpApp(app);
      } else {

        const dialogRef = this.RdpLaunchDialog.open(RdpLaunchDialogComponent, {
          data: {
            uuid: this.uuid,
            showSessionRecording: this.showSessionRecordingPrompt
          }
        });
        dialogRef.afterClosed()
          .subscribe(selection => {
            if (selection) {

              const originalPath = app.accessString.split('?', 1);
              if (selection.uuid && selection.type === 'multi') {
                if (this.showVmonPrompt == 2) {
                  document.cookie = app.instanceId.toString() + "_vmon_uuid=" + selection.uuid + "; path=/; expires=Tue, 19 Jan 2038 03:14:07 UTC; secure; samesite";
                }
                app.accessString = originalPath[0] + this.vmonParam + selection.uuid;
              } else if (selection.type === 'single') {
                app.accessString = originalPath[0];
              }
              this.LaunchRdpApp(app);
            }
          });

      }

    } else if (this.showSessionRecordingPrompt) {

      const dialogRefSessionRecording = this.RdpLaunchDialog.open(SessionRecordingDialog);

      dialogRefSessionRecording.afterClosed()
        .subscribe(selection => {
          if (selection) {
            if (selection === 'ok') {
              this.LaunchRdpApp(app);
            }
          }
        });

    }
  }

  LaunchRdpApp(app: NextGenApplication) {
    localStorage.setItem('appInstanceId', String(app.instanceId));
    localStorage.setItem('appName', String(app.instanceName));

    var win = window.open(app.accessString, '_blank');
    //Check if AdminPlugin
    if (app.appTypeName == "Admin") {
      this.openWindows.push(win);
      win.focus();
    } else {
      this.requestValidateLicenseForApp.data.appId = app.instanceId;
      this.requestValidateLicenseForApp.data.appName = app.instanceName;
      this.requestValidateLicenseForApp.data.userId = this.currentUserId;
      if (this.currentUserId == 1) {
        win.focus();
      } else {


        this.rpcService.postRequest(this.requestValidateLicenseForApp)
          .subscribe(resValidateLicenseForApp => {
            this.replyValidateLicenseForApp = resValidateLicenseForApp;

            if (!!this.replyValidateLicenseForApp.data) {
              if (this.replyValidateLicenseForApp.data.valid) {
                this.openWindows.push(win);
                if (!!win) {
                  win.onbeforeunload = (ev: BeforeUnloadEvent): any => {
                    this.returnConcurrentLicense(app.instanceId);
                  }
                  win.onunload = (ev: Event): any => {
                    this.returnConcurrentLicense(app.instanceId);
                  }
                }
                win.focus();
              } else {
                win.close();
              }
            }
          }, resError => {
            console.error(resError);
            win.close();
          })
      }
    }
  }


  @HostListener('window:beforeunload', ['$event'])
  public beforeunloadHandler($event) {
    if (!this.refreshFromLanguageChange) {
      //this.logout();
    }
  }

  @HostListener('window:unload', ['$event'])
  public unloadHandler($event) {
    if (!this.refreshFromLanguageChange) {
      //this.logout();
    }
  }

  logout() {
    try {
      this.tokenTimeoutSubscription.unsubscribe();
      this.tokenTimeoutSubscription = null;
    } catch (ex) {
      console.log(ex);
    }

    try {
      this.tokenActivitySubscription.unsubscribe();
      this.tokenActivitySubscription = null;
    } catch (ex) {
      console.log(ex);
    }
    this.rpcService.postRequest(this.requestlogout)
      .subscribe(
        reslogout => {
          this.replyLogout = reslogout;
          if (Number(this.replyLogout.status) < 0) {
            this.router.navigate(['/login'])
            return;
          }
          try {
            this.authService.logout();
          } catch (ex) {

          }
        }),
      responseLogout => {
        this.errorMsg = responseLogout
        console.log("errorMsg ::" + this.errorMsg)
      };
    this.closeOpenWindows();
    localStorage.setItem('userToken', undefined);

    /*delay added to allow time to clear clientId; so
    azure auth doesnt run in a loop */
    if (localStorage.getItem('clientId') != null) {
      localStorage.removeItem('clientId');

      setTimeout(() => {
        this.router.navigate(['/login'])
      }
        , 2000)
    } else {
      this.router.navigate(['/login'])
    }

  }

  isSelectedLanguage(languageCode: string) {
    return localStorage.getItem('currentLocale') === languageCode;
  }

  selectLanguage(languageCode: string) {
    this.refreshFromLanguageChange = true;
    this.currentLocale = languageCode;
    localStorage.setItem('currentLocale', languageCode);
    window.location.reload();
  }

  getCurrentImageHref() {
    return this.languageImages[localStorage.getItem('currentLocale')];
  }

  getImageHref(languageCode: string) {
    return this.languageImages[languageCode];
  }

  closeOpenWindows() {
    for (var i = 0; i < this.openWindows.length; i++) {
      this.openWindows[i].close();
    }
  }

  checkTokenTimeout() {
    this.rpcService.postRequest(this.requestCheckTokenTimeout)
      .subscribe(
        resCheckTokenTimeout => {
          this.replyCheckTokenTimeout = resCheckTokenTimeout;
          if (this.replyCheckTokenTimeout.data.tokenTimedOut) {
            this.logout();
            alert("Session timed out!");
          }
        }),
      responseCheckTokenTimeout => {
        this.errorMsg = responseCheckTokenTimeout
        console.log("errorMsg ::" + this.errorMsg)
      };
  }

  checkTokenActivity() {
    this.rpcService.postRequest(this.requestCheckTokenActivity)
      .subscribe(
        resCheckTokenActivity => {
          this.replyCheckTokenActivity = resCheckTokenActivity;
          if (this.replyCheckTokenActivity.data.tokenChanged) {
            this.logout();
            alert("Login from another location!\nOS: " + this.replyCheckTokenActivity.data.toOS + "\nBrowser: " + this.replyCheckTokenActivity.data.toBrowser);
          }
        }),
      responseCheckTokenActivity => {
        this.errorMsg = responseCheckTokenActivity
        console.log("errorMsg ::" + this.errorMsg)
      };
  }

  returnConcurrentLicense(appId: number) {
    this.requestReturnConcurrentLicense.data.appId = appId;
    this.requestReturnConcurrentLicense.data.userId = this.currentUserId;
    this.rpcService.postRequest(this.requestReturnConcurrentLicense)
      .subscribe(resReturnConcurrentLicense => {
        this.replyReturnConcurrentLicense = resReturnConcurrentLicense;
        if (!!this.replyReturnConcurrentLicense.data) {
          if (this.replyReturnConcurrentLicense.data.valid) {
            console.log("Returned license");
          } else {
            console.log("Failed to return license");
          }
        }
      })
  }

  getLyfContainerWidth(): string {
    // if (this.stdApps.length > 0) {
    //   return '35%';
    // } else {
    return '99%';
    // }
  }

  isCurrentLocaleCorrupt(): boolean {
    let currentLocale = localStorage.getItem('currentLocale');
    return (currentLocale === undefined) || (currentLocale === null) || (currentLocale === '') || (currentLocale.includes('undefined'));
  }

  isTokenCorrupt(): boolean {
    let userToken = localStorage.getItem('userToken');
    return (userToken === undefined) || (userToken === null) || (userToken === '') || (userToken.includes('undefined'));
  }

  getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if (name == cookiePair[0].trim()) {
        // Decode the cookie value and return
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  }

}
