import { Injectable } from '@angular/core';
import { RpcService } from './rpc.service';
import { RequestGetAuthenticationStage, ReplyGetAuthenticationStage, FieldNameValue, RequestAuthenticateUser } from './login/authMessages';
import { Router } from '@angular/router';
import { getBrowserName, getOSName } from './browserFunctions';
import { Configuration } from '@azure/msal-browser';

let appConfig: any;

export function configurator() {
  return appConfig;
}



// Azure specific configuration 
@Injectable()
export class AzureService {
  private adalConfig: string;
  tenant: string;
  clientId: string;
  token: string;
  stageNumber: number;

  countdownSeconds: number = 5;

  requestGetAuthenticationStage: RequestGetAuthenticationStage;
  replyGetAuthenticationStage: ReplyGetAuthenticationStage;

  constructor(private rpcService: RpcService, private router: Router) {
    this.requestGetAuthenticationStage = new RequestGetAuthenticationStage();
  }

  public loadAppConfig(): Promise<any> {
    if (window.location.hash != '#/masteradminlogin') {
      this.stageNumber = 1;
      this.requestGetAuthenticationStage.data.stage = this.stageNumber;
      const promise = this.rpcService.postRequest(this.requestGetAuthenticationStage)
        .toPromise()
        .then(resGetAuthenticationStage => {
          this.replyGetAuthenticationStage = resGetAuthenticationStage
          this.replyGetAuthenticationStage.data.prompts.forEach(prompt => {
            if ((prompt.auth_prompt_name == "redirectURI")) {
              this.setAdalConfig(this.replyGetAuthenticationStage.data.instanceFields);
              appConfig = this.getAdalConfig();
              return appConfig;
            }
          });
        });
      return promise;
    } else {
      this.resetAdalConfig();
    }
  }

  public authenticate(accessToken: string, username: string): void {
    let requestAuthenticateUser = new RequestAuthenticateUser();

    let list = new Array<FieldNameValue>();

    let fieldVal = new FieldNameValue();
    fieldVal.name = "username";
    fieldVal.value = username;
    list.push(fieldVal)

    requestAuthenticateUser.data.user_fields = list;
    requestAuthenticateUser.data.os = getOSName();
    requestAuthenticateUser.data.browser = getBrowserName();
    requestAuthenticateUser.data.stage = 1;
    requestAuthenticateUser.data.thirdPartyToken = accessToken;
    this.rpcService.postRequest(requestAuthenticateUser)
      .subscribe(
        response => {
          let replyAuthenticateUserRes = response
          if (replyAuthenticateUserRes.data.authenticated) {
            localStorage.setItem('userToken', replyAuthenticateUserRes.data.userToken);
            if (replyAuthenticateUserRes.data.nextStage === -1 || replyAuthenticateUserRes.data.nextPrompts.length === 0) {
              this.router.navigate(['workspace']);
            }
          }
        },
        responseError => {
          console.log(responseError)
          this.router.navigate(['login']);
        });
  }

  public getAdalConfig(): Configuration {
    return {
      auth: {
        // tenant: localStorage.getItem('tenant'),
        clientId: localStorage.getItem('clientId'),
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: true,
        authority: "https://login.microsoftonline.com/" + localStorage.getItem('tenant') + "/",
      }
    };
  }

  public setAdalConfig(map: Map<string, string>): any {
    for (const key in map) {
      if (key === "clientId") {
        // this.clientId = map[key];
        localStorage.setItem('clientId', map[key]);
      } else if (key === "tenant") {
        // this.tenant = map[key];
        localStorage.setItem('tenant', map[key]);
      }
    }
  }

  public resetAdalConfig() {
    localStorage.removeItem('clientId');
    localStorage.removeItem('tenant');
  }


  public getClientID(): string {
    return localStorage.getItem('clientId')
  }



  public getAuthority() {
    return "https://login.microsoftonline.com/" + localStorage.getItem('tenant') + "/";
  }

  /* public setToken(): void {
         this.adalService.acquireToken(localStorage.getItem('tenant')).subscribe((resToken: string) =>{
             this.token = resToken;
        })
   }
   public getToken() {
       return this.token;
   }
    
   public getUserName(){
       return this.adalService.userInfo.userName;
   }*/

}
