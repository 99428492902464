import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './firsttimeaccess.component.html',
  styleUrls: ['./firsttimeaccess.component.css']
})
export class FirstTimeAccessComponent {
  showError:boolean;

  errorMsg:string;
  showLoading: boolean;

  change(value:boolean):void {
   
    if(value){
      this.showError=false;
    }else {
      this.showError=true;
    }
  }
  constructor(private router: Router){
  }

  goToEula(){
    window.location.href = "https://" + window.location.hostname + "/#/eula";
    //this.router.navigate(['/eula']);
  }
}
