import { Component, OnInit } from '@angular/core';
import { AzureService } from '../azure.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './redirecting.component.html',
  styleUrls: ['./redirecting.component.css'],
  providers: []
})

export class RedirectComponent implements OnInit {


  constructor(private msalService: MsalService, private azureService: AzureService, private router: Router) {

  }

  ngOnInit() {
    const accounts = this.msalService.instance.getAllAccounts();

    if (accounts.length > 0) {
      const firstAccount = accounts[0];
      this.msalService.instance.setActiveAccount(firstAccount)
    } else {
      console.error("No accounts found.");
      this.router.navigate(['login']);
    }
    this.msalService.acquireTokenSilent({ scopes: ['user.read'] })
      .subscribe((response) => {
        this.azureService.authenticate(response.accessToken, this.msalService.instance.getActiveAccount().username)
      }), responseError => {
        console.log(responseError)
        this.router.navigate(['login']);
      }
  }
}
