import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RpcService } from '../../rpc.service';
import { LoginFieldBase } from './loginfield-base';
import { LoginFormControlService } from './loginform-control.service';
import { TextboxLogin } from './login-textbox';
import {
  RequestAuthenticateUser, ReplyAuthenticateUser, FieldNameValue,
  RequestGetDefaultAuthInst, ReplyGetDefaultAuthInst,
  RequestGetDefaultAuthenticationPrompts, ReplyGetDefaultAuthenticationPrompts,
  RequestGetAuthenticationStage, ReplyGetAuthenticationStage, RequestPreStageAction,
  ReplyPreStageAction, RequestDeleteTotpSecretKey, ReplyDeleteTotpSecretKey
} from '../authMessages';

import { I18n } from '@ngx-translate/i18n-polyfill';
import { MsalService } from '@azure/msal-angular';
import { AzureService } from '../../azure.service';
import { MatDialog } from '@angular/material/dialog';
import { QrCodeModal } from './qrCodeModal/qrCodeModal.component';

@Component({
  selector: 'loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['../login.component.css'],
  providers: [LoginFormControlService, RpcService]
})

/**
 * @author Marius Rettler
 * @description Creates the login prompts to the specified authentication instance
 */
export class LoginFormComponent implements OnInit, AfterViewInit {

  @Input() loginField: LoginFieldBase<any>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.loginField.name].valid; }

  showLoading: boolean;
  // form: FormGroup;
  loginFields: LoginFieldBase<any>[];
  wrongCredentials: boolean;
  showErr: boolean;

  autofocusFieldName: string = ""

  requestAuthenticateUser: RequestAuthenticateUser;
  replyAuthenticateUser: boolean;
  replyAuthenticateUserRes: ReplyAuthenticateUser;

  requestGetDefaultAuthInst: RequestGetDefaultAuthInst;
  replyGetDefaultAuthInst: number;
  replyGetDefaultAuthInstRes: ReplyGetDefaultAuthInst;

  requestGetDefaultAuthPrompts: RequestGetDefaultAuthenticationPrompts;
  replyGetDefaultAuthPromptsRes: ReplyGetDefaultAuthenticationPrompts;

  requestGetAuthenticationStage: RequestGetAuthenticationStage;
  replyGetAuthenticationStage: ReplyGetAuthenticationStage;

  requestPreStageAction: RequestPreStageAction;
  replyPreStageAction: ReplyPreStageAction;

  requestDeleteTotpSecretKey: RequestDeleteTotpSecretKey;
  replyDeleteTotpSecretKey: ReplyDeleteTotpSecretKey;


  errorMsg: string;
  showLoginForm: boolean = false;
  showAzureLogin: boolean = false;
  showNextStageForm: boolean;
  stageNumber: number;
  masterUserId: number;
  stageUserId: number;
  authInstId: number;

  showDescription: boolean;
  description: string;

  public countdownVariable: number = 5

  @ViewChildren('dynamicInput') dynamicInputs: QueryList<ElementRef>;

  constructor(private i18n: I18n, private lfcs: LoginFormControlService,
    private rpcService: RpcService, private router: Router,
    private renderer2: Renderer2, public authService: MsalService, public azureService: AzureService,
    public qrCodeModal: MatDialog, private cdr: ChangeDetectorRef) {

    this.requestAuthenticateUser = new RequestAuthenticateUser();
    this.requestGetDefaultAuthInst = new RequestGetDefaultAuthInst();
    this.requestGetDefaultAuthPrompts = new RequestGetDefaultAuthenticationPrompts();
    this.requestGetAuthenticationStage = new RequestGetAuthenticationStage();
    this.requestPreStageAction = new RequestPreStageAction();
    this.requestDeleteTotpSecretKey = new RequestDeleteTotpSecretKey();
    this.form = this.createEmptyFormGroup();

  }

  ngOnInit() {
    this.showLoading = false;
    this.getFirstAuthStage();
  }

  ngAfterViewInit() {
    this.dynamicInputs.changes.subscribe(() => {
      this.setAutofocus();
    });
    this.cdr.detectChanges(); // Trigger change detection to ensure changes are detected
  }

  getFirstAuthStage() {
    this.loginFields = [];
    this.stageNumber = 1;
    this.showNextStageForm = false;
    this.requestGetAuthenticationStage.data.stage = this.stageNumber;
    this.rpcService.postRequest(this.requestGetAuthenticationStage)
      .subscribe(
        resGetAuthenticationStage => {
          this.replyGetAuthenticationStage = resGetAuthenticationStage

          this.showDescription = this.replyGetAuthenticationStage.data.showDescription;

          if (this.showDescription == true) {
            this.description = this.replyGetAuthenticationStage.data.description;
          }

          this.replyGetAuthenticationStage.data.prompts.forEach(prompt => {
            if (prompt.auth_prompt_name == "redirectURI") {
              this.showLoginForm = false;
              this.showAzureLogin = true;
            } else {
              if (prompt.auth_prompt_type == "PASSWORD") {
                prompt.auth_prompt_type = 'password';
              }
              this.showAzureLogin = false;
              this.showLoginForm = true;

              prompt.auth_prompt_label = this.i18n({ id: prompt.auth_prompt_label, value: "none" });

              this.loginFields.push(
                new TextboxLogin({
                  value: prompt.auth_prompt_label,
                  id: prompt.auth_prompt_id,
                  name: prompt.auth_prompt_name,
                  label: prompt.auth_prompt_label,
                  description: prompt.auth_prompt_description,
                  type: prompt.auth_prompt_type,
                  order: prompt.auth_prompt_order
                }));
            }
          });
          this.form = this.lfcs.toFormGroup(this.loginFields.sort((a, b) => a.order - b.order));
          this.loginFields.forEach((item) => {
            if(item.autofocus){
              this.autofocusFieldName = item.name;
            }
          });

        },
        responseGetDefaultAuthInst => {
          this.errorMsg = responseGetDefaultAuthInst
          this.showError(this.errorMsg);
        });
  }

  onLogin(loginForm: NgForm) {
    this.showLoading = true;
    // this.requestAuthenticateUser.data.auth_inst_id = this.stageNumber;
    let list = new Array<FieldNameValue>();
    for (let key in loginForm.value) {
      let fieldVal = new FieldNameValue();
      fieldVal.name = key;
      fieldVal.value = encodeURIComponent(loginForm.value[key]);
      list.push(fieldVal)
    }

    this.requestAuthenticateUser.data.user_fields = list;
    this.requestAuthenticateUser.data.os = this.getOSName();
    this.requestAuthenticateUser.data.browser = this.getBrowserName();
    this.requestAuthenticateUser.data.stage = this.stageNumber;
    this.requestAuthenticateUser.data.master_user_id = this.masterUserId;
    this.requestAuthenticateUser.data.sessionId = localStorage.getItem("sessionId");

    this.rpcService.postRequest(this.requestAuthenticateUser)
      .subscribe(
        resAuthCreateInstData => {
          this.replyAuthenticateUserRes = resAuthCreateInstData
          localStorage.setItem("sessionId", this.replyAuthenticateUserRes.data.sessionId);
          this.replyAuthenticateUser = this.replyAuthenticateUserRes.data.authenticated;
          if (this.replyAuthenticateUser) {
            localStorage.setItem('userToken', this.replyAuthenticateUserRes.data.userToken);
            this.stageUserId = this.replyAuthenticateUserRes.data.userid;
            if (this.replyAuthenticateUserRes.data.nextStage === -1 || this.replyAuthenticateUserRes.data.nextPrompts.length === 0) {
              this.showLoading = false;
              this.router.navigate(['/workspace']);
            } else {
              if (this.replyAuthenticateUserRes.data.nextUserPrevCredentials) {
                this.requestAuthenticateUser.data.stage = this.replyAuthenticateUserRes.data.nextStage;
                this.requestAuthenticateUser.data.master_user_id = this.replyAuthenticateUserRes.data.master_user_id;
                this.loginAgain(this.requestAuthenticateUser);
              } else {
                // this.router.navigate(['nextStageLogin']);
                this.showPromptAgain(this.replyAuthenticateUserRes);
                this.showLoading = false;
              }
            }
          }
          else {
            this.showLoading = false;
            this.wrongCredentials = true;
            //wait 5 Seconds and hide
            setTimeout(function () {
              this.wrongCredentials = false;
            }.bind(this), 5000);
            if (this.stageNumber > 1) {
              this.getFirstAuthStage();
            }
          }
        },
        responseError => {
          this.showLoading = false;
          this.errorMsg = responseError
          this.showError(this.errorMsg);
        });
  }


  loginAgain(requestAuthenticateUser: RequestAuthenticateUser) {
    this.rpcService.postRequest(requestAuthenticateUser)
      .subscribe(
        resAuthCreateInstData => {
          this.replyAuthenticateUserRes = resAuthCreateInstData
          this.replyAuthenticateUser = this.replyAuthenticateUserRes.data.authenticated;
          localStorage.setItem("sessionId", this.replyAuthenticateUserRes.data.sessionId);
          this.requestAuthenticateUser.data.sessionId = localStorage.getItem("sessionId");
          if (this.replyAuthenticateUserRes.data.nextStage === -1 || this.replyAuthenticateUserRes.data.nextPrompts.length === 0) {
            localStorage.setItem('userToken', this.replyAuthenticateUserRes.data.userToken);
            this.router.navigate(['/workspace']);
          } else {
            if (this.replyAuthenticateUserRes.data.nextUserPrevCredentials) {
              this.requestAuthenticateUser.data.stage = this.replyAuthenticateUserRes.data.nextStage;
              this.requestAuthenticateUser.data.master_user_id = this.replyAuthenticateUserRes.data.master_user_id;

              this.loginAgain(this.requestAuthenticateUser);
            } else {
              this.showPromptAgain(this.replyAuthenticateUserRes);
            }
          }
        });
  }

  openQRCodeModal(qrCodeEncoded: string, secretKey: string): void {
    var formattedKey: string;

    formattedKey = secretKey.substring(0, 4) + " " + secretKey.substring(4, 8) + " " + secretKey.substring(8, 12) + " " + secretKey.substring(12, 16) + " " +
      secretKey.substring(16, 20) + " " + secretKey.substring(20, 24) + " " + secretKey.substring(24, 28) + " " + secretKey.substring(28);

    const dialogRef = this.qrCodeModal.open(QrCodeModal, {
      data: {
        qrCodeData: qrCodeEncoded,
        key: formattedKey
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!(result === "OK")) {
        this.requestDeleteTotpSecretKey.data.userId = this.masterUserId;
        this.rpcService.postRequest(this.requestDeleteTotpSecretKey)
          .subscribe(resDeleteTotpSecretKey => {
            console.log("Going to redirect 17");
            //this.router.navigate(['']);
            window.location.href = "https://" + window.location.hostname + "/";
          });
      }
    });
  }

  doPreStageAction(userid: number, stage: number) {

    this.requestPreStageAction.data.userId = userid;
    this.requestPreStageAction.data.stage = stage;

    this.rpcService.postRequest(this.requestPreStageAction)
      .subscribe(
        resPreStageAction => {
          this.replyPreStageAction = resPreStageAction;
          if (this.replyPreStageAction.data.fields["type"] == "TOTP") {
            this.openQRCodeModal(this.replyPreStageAction.data.fields["qrcode"],
              this.replyPreStageAction.data.fields["key"]);
          }
        }
      )
  }


  showPromptAgain(replyAuthenticateUserRes) {

    this.loginFields = [];
    this.showLoginForm = false;
    this.showNextStageForm = true;

    // preaction call
    this.doPreStageAction(this.stageUserId, this.stageNumber + 1);

    replyAuthenticateUserRes.data.nextPrompts.forEach(prompt => {
      if (prompt.auth_prompt_name == "redirectURI") {
        this.showAzureLogin = true;
        this.showNextStageForm = false;
      } else {
        if (prompt.auth_prompt_type == "PASSWORD") {
          prompt.auth_prompt_type = 'password';
        }
        this.showAzureLogin = false;

        this.showDescription = replyAuthenticateUserRes.data.showDescription;

        if (this.showDescription) {
          this.description = replyAuthenticateUserRes.data.description;
        }

        //prompt.auth_prompt_label = this.i18n({ id: prompt.auth_prompt_label, value: "none" });

        this.loginFields.push(
          new TextboxLogin({
            value: prompt.auth_prompt_label,
            id: prompt.auth_prompt_id,
            name: prompt.auth_prompt_name,
            label: prompt.auth_prompt_label,
            description: prompt.auth_prompt_description,
            type: prompt.auth_prompt_type,
            order: prompt.auth_prompt_order
          }));
      }
    });

    this.form = this.lfcs.toFormGroup(this.loginFields.sort((a, b) => a.order - b.order));
    this.loginFields.forEach((item) => {
      if(item.autofocus){
        this.autofocusFieldName = item.name;
      }
    });

    this.stageNumber = this.replyAuthenticateUserRes.data.nextStage;
    this.masterUserId = this.replyAuthenticateUserRes.data.master_user_id;

  }

  mouseenter(event) {
    this.renderer2.addClass(event.target, 'mat-elevation-z9')
  }

  mouseleave(event) {
    this.renderer2.removeClass(event.target, 'mat-elevation-z9')
  }

  /* createLoginForm() {
     this.loginFields = [];
     this.rpcService.postRequest(this.requestGetDefaultAuthPrompts)
       .subscribe(
         resDefaultAuthPrompts => {
           this.replyGetDefaultAuthPromptsRes = resDefaultAuthPrompts;
           this.replyGetDefaultAuthPromptsRes.data.prompts.forEach(prompt => {
             if (prompt.auth_prompt_type == "PASSWORD") {
               prompt.auth_prompt_type = 'password';
             }
             prompt.auth_prompt_label = this.i18n({id: prompt.auth_prompt_label, value: "none"});
             this.loginFields.push(
               new TextboxLogin({
                 value: prompt.auth_prompt_label,
                 id: prompt.auth_prompt_id,
                 name: prompt.auth_prompt_name,
                 label: prompt.auth_prompt_label,
                 description: prompt.auth_prompt_description,
                 type: prompt.auth_prompt_type,
                 order: prompt.auth_prompt_order
               }));
           });
           this.form = this.lfcs.toFormGroup(this.loginFields.sort((a, b) => a.order - b.order));
         },
         responseLoginFieldError => {
           this.errorMsg = responseLoginFieldError;
           this.showError(this.errorMsg);
         });
   }*/

  createEmptyFormGroup() {
    var tmpArr: LoginFieldBase<any>[] = [];
    tmpArr.push(new TextboxLogin({
      value: '',
      id: 1,
      name: 'dummy',
      label: '',
      description: '',
      type: 'textbox',
      order: 1
    }));
    return this.lfcs.toFormGroup(tmpArr);
  }

  showError(msg: string) {
    msg = msg.toString();

    if (msg.length > 82) {

      msg = msg.substring(0, 82) + "...";

    }
    this.errorMsg = msg;
    this.showErr = true;
    //wait 5 Seconds and hide
    setTimeout(function () {
      this.errorMsg = msg;
      this.showErr = false;
    }.bind(this), 5000);
  }

  getBrowserName(): string {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/" 
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return browserName;
  }

  getOSName(): string {

    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;

    var os = 'unknown';
    var clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    for (var id in clientStrings) {
      var cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    var osVersion = 'unknown';

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        osVersion = /Mac OS X (1[0|1][\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        let versions: Array<string>;
        versions = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = versions[1] + '.' + versions[2];
        break;
    }
    return os + ' ' + osVersion;
  }

  private setAutofocus(): void {
    this.dynamicInputs.forEach(input => {
      const element = input.nativeElement as HTMLInputElement;
      if (element.getAttribute('name') === this.autofocusFieldName) {
        element.focus();
      }
    })
  }
}