<div *ngIf="showLoginForm">


    <mat-card (mouseenter)='mouseenter($event)' (mouseleave)="mouseleave($event)" class="login-box-body">

        <div *ngIf="showDescription && description">
            <h2 style="text-align:center">{{ description }}</h2>
        </div>


        <form #loginForm="ngForm" class="example-form" [formGroup]="form" (ngSubmit)="onLogin(loginForm)">
            <mat-form-field class="login-input" *ngFor="let loginField of loginFields">
                <input matInput class="inputField" [formControlName]="loginField.name" id="{{loginField.id}}"
                    name="{{loginField.name}}" type="{{loginField.type}}" placeholder="{{loginField.label}}"
                    #dynamicInput>
            </mat-form-field>

            <button mat-raised-button color="primary" type="submit" class="btn-block" name="submit" id="submit"
                [disabled]="!form.valid || showLoading">
                <span [hidden]="!showLoading" class="spinner">
                    <i class="glyphicon glyphicon-refresh gly-spin"></i>
                </span>
                <span i18n="@@LoginUppercase">Login</span>
            </button>
            <div class="alert alert-danger error" *ngIf="wrongCredentials">
                <strong i18n="@@LoginIncorrectCredentialsBold">Your authentication information is incorrect!</strong>
                <p i18n="@@LoginIncorrectCredentials">Please try again.</p>
            </div>
            <div class="alert alert-danger error" *ngIf="showErr">
                <p>{{errorMsg}}</p>
            </div>
        </form>
    </mat-card>
</div>

<div *ngIf="showAzureLogin">
    <button mat-raised-button color="primary" type="submit" class="btn-block" name="submit" id="submit"
        (click)="this.authService.loginRedirect()">
        <span i18n="@@LoginUppercase">Click to Continue</span>
    </button>
</div>

<div *ngIf="showNextStageForm">



    <mat-card (mouseenter)='mouseenter($event)' (mouseleave)="mouseleave($event)" class="login-box-body">

        <div *ngIf="showDescription">
            <h2 style="text-align:center">{{ description }}</h2>
        </div>


        <form #loginForm="ngForm" class="example-form" [formGroup]="form" (ngSubmit)="onLogin(loginForm)">
            <mat-form-field class="login-input" *ngFor="let loginField of loginFields">
                <input matInput class="inputField" [formControlName]="loginField.name" id="{{loginField.id}}"
                    name="{{loginField.name}}" type="{{loginField.type}}" placeholder="{{loginField.label}}"
                    #dynamicInput>
            </mat-form-field>

            <button mat-raised-button color="primary" type="submit" class="btn-block" name="submit" id="submit"
                [disabled]="!form.valid || showLoading">
                <span [hidden]="!showLoading" class="spinner">
                    <i class="glyphicon glyphicon-refresh gly-spin"></i>
                </span>
                <span i18n="@@LoginUppercase">Login</span>
            </button>
            <div class="alert alert-danger error" *ngIf="wrongCredentials">
                <strong i18n="@@LoginIncorrectCredentialsBold">Your authentication information is incorrect!</strong>
                <p i18n="@@LoginIncorrectCredentials">Please try again.</p>
            </div>
            <div class="alert alert-danger error" *ngIf="showErr">
                <p>{{errorMsg}}</p>
            </div>
        </form>
    </mat-card>
</div>