<header>
    <table class="tableheader">
        <tr>
            <td style="width:85px; background-color:#2C3643">
                <img width="50" height="32" src="assets/images/netconnectlogo.png"
                    style="padding: 5px; margin: 0 auto; display: block">
            </td>
            <td i18n="@@WelcomeBackExclamationMark" style="width:26%">
                NetConnect
            </td>
            <td>
                <button mat-button style="float: right;" [matMenuTriggerFor]="options">
                    <span style="margin-right: 15px;">
                        <img align="left" src="assets/images/user.png">
                    </span>
                    <span>
                        {{this.currentUsername}}
                    </span>
                </button>

                <mat-menu #options="matMenu">
                    <!--<button mat-menu-item [matMenuTriggerFor]="language">Language</button>-->
                    <button mat-menu-item (click)="logout()" i18n="@@UserLogout">Logout</button>

                </mat-menu>

                <mat-menu #language="matMenu">
                    <button mat-menu-item (click)="selectLanguage('en-US')">EN</button>
                    <!-- <button mat-menu-item (click)="selectLanguage('de-DE')">DE</button> -->
                    <button mat-menu-item (click)="selectLanguage('fr-FR')">FR</button>
                </mat-menu>
            </td>
        </tr>
    </table>
</header>



<main style="background-color: #F3F3F3;">
    <div class="app-container">
        <div class="apps">
            
                <mat-card class="stdapp" (mouseenter)='mouseenter($event)' (mouseleave)="mouseleave($event)"
                *ngFor="let app of apps" (click)="accessAppInstance(app)">
                    <img src="data:image/PNG;base64,{{app.instanceImage}}" class="appimage">
                    <mat-card-header style="justify-content: center;">
                        <mat-card-title style="font-size: 16px;">{{app.instanceName}}</mat-card-title>
                        <div class="spinner-container" *ngIf="app.status">
                            <mat-spinner [diameter]="25"></mat-spinner>
                        </div>
                    </mat-card-header>
                </mat-card>
            
           
            <mat-card *ngIf="displayNoAppsError" class="login-box-body">
                <p i18n="@@WorkspaceNoApplicationsError">User has no assigned applications! Please contact your system
                    administrator.</p>
                <!-- <button mat-raised-button color="primary" type="submit" (click)="submit()" class="btn-block">Return</button> -->
            </mat-card>
           
        </div>
    </div>
    
    <div>
    </div>
</main>



<footer>
    <table class="tablefooter">
    </table>
</footer>