import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LoginFieldBase }     from './loginfield-base';
 
@Component({
  selector: 'df-loginField',
  templateUrl: './loginform-field.component.html',
  styleUrls: ['../login.component.css']
})
export class LoginFormFieldComponent{
  @Input() loginField: LoginFieldBase<any>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.loginField.name].valid; }
}