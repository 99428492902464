<h1 class="mbr-section-title pb-3 mbr-white mbr-bold mbr-fonts-style display-1 align-center" align="center">Session Recording</h1>
  <div class="mbr-fonts-style display-5" [style.color]="'red'" >
    Warning!  This session is being recorded....
  </div>
  <br/>
  <div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" mat-button 
      (click)="onOk()">Ok</button>

      <button mat-raised-button color="primary" mat-button 
      (click)="onCancel()">Cancel</button>

  </div>