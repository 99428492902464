export const TRANSLATION_deDE = `<?xml version="1.0" encoding="UTF-8" ?>
<xliff version="1.2" 
    xmlns="urn:oasis:names:tc:xliff:document:1.2">
    <file source-language="en" datatype="plaintext" original="ng2.template">
        <body>
            <trans-unit id="WelcomeBackExclamationMark" datatype="html">
                <source>Welcome Back!</source>
                <target>Willkommen zurück!</target>
                <note priority="1" from="description">Welcome Back with exclamation mark</note>
            </trans-unit>
            <trans-unit id="LoginUppercase" datatype="html">
                <source>LOGIN</source>
                <target>LOGIN</target>
                <note priority="1" from="description">Login in uppercase</note>
            </trans-unit>
            <trans-unit id="UserLogout" datatype="html">
                <source>Logout</source>
                <target>Ausloggen</target>
                <note priority="1" from="description">Logout Button for the User</note>
            </trans-unit>
            <trans-unit id="ApplicationsHeader" datatype="html">
                <source>Applications</source>
                <target>Anwendungen</target>
                <note priority="1" from="description">Header for the Applications Section</note>
            </trans-unit>
            <trans-unit id="LyfHeader" datatype="html">
                <source>LYF</source>
                <target>LYF</target>
                <note priority="1" from="description">Header for the Lyf Applications</note>
            </trans-unit>
            <trans-unit id="PrivacyPolicyLink" datatype="html">
                <source>Privacy Policy</source>
                <target>Datenschutz</target>
                <note priority="1" from="description">Link to see the Privacy Policies</note>
            </trans-unit>
            <trans-unit id="ContactUsLink" datatype="html">
                <source>Contact Us</source>
                <target>Kontakt</target>
                <note priority="1" from="description">Link for the Contact formular</note>
            </trans-unit>
            <trans-unit id="MyAccountLink" datatype="html">
                <source>My Account</source>
                <target>Mein Konto</target>
                <note priority="1" from="description">Link to the My Account Page</note>
            </trans-unit>
            <trans-unit id="HelpLink" datatype="html">
                <source>Help</source>
                <target>Hilfe</target>
                <note priority="1" from="description">Link to navigate to the Help page</note>
            </trans-unit>
            <trans-unit id="LyfTypeFilterHeader" datatype="html">
                <source>Type</source>
                <target>Typ</target>
                <note priority="1" from="description">Header for the Lyf Filter</note>
            </trans-unit>
            <trans-unit id="LyfFilterAll" datatype="html">
                <source>All</source>
                <target>Alle</target>
                <note priority="1" from="description">The All Filter in Lyf</note>
            </trans-unit>
            <trans-unit id="LyfFilesFilter" datatype="html">
                <source>Files</source>
                <target>Dateien</target>
                <note priority="1" from="description">The File Filter in Lyf</note>
            </trans-unit>
            <trans-unit id="LyfFoldersFilter" datatype="html">
                <source>Folders</source>
                <target>Ordner</target>
                <note priority="1" from="description">The folders filter in Lyf</note>
            </trans-unit>
            <trans-unit id="LyfDirectoryHeader" datatype="html">
                <source>Directory</source>
                <target>Verzeichnis</target>
                <note priority="1" from="description">The DirectoryHeader in Lyf</note>
            </trans-unit>
            <trans-unit id="LyfInfoButton" datatype="html">
                <source>Info</source>
                <target>Info</target>
                <note priority="1" from="description">The info button in Lyf</note>
            </trans-unit>
            <trans-unit id="LyfNewFolderButton" datatype="html">
                <source>New Folder</source>
                <target>Neuer Ordner</target>
                <note priority="1" from="description">The new Folder button in Lyf</note>
            </trans-unit>
            <trans-unit id="LyfUploadButton" datatype="html">
                <source>Upload</source>
                <target>Hochladen</target>
                <note priority="1" from="description">The button for lyf upload</note>
            </trans-unit>
            <trans-unit id="LyfFileHeader" datatype="html">
                <source>File</source>
                <target>Datei</target>
                <note priority="1" from="description">Header for the File view</note>
            </trans-unit>
            <trans-unit id="LyfFolderItemDescription" datatype="html">
                <source>Folder</source>
                <target>Ordner</target>
                <note priority="1" from="description">The Description when an item is a folder</note>
            </trans-unit>
            <trans-unit id="LyfRenameFunction" datatype="html">
                <source>Rename</source>
                <target>Umbenennen</target>
                <note priority="1" from="description">The lyf Rename function</note>
            </trans-unit>
            <trans-unit id="LyfDeleteFunction" datatype="html">
                <source>Delete</source>
                <target>Entfernen</target>
                <note priority="1" from="description">The delete function in lyf</note>
            </trans-unit>
            <trans-unit id="LyfDownloadFunction" datatype="html">
                <source>Download</source>
                <target>Runterladen</target>
                <note priority="1" from="description">The Lyf download function</note>
            </trans-unit>
            <trans-unit id="AdminDashboard" datatype="html">
                <source>DASHBOARD</source>
                <target>ÜBERSICHT</target>
                <note priority="1" from="description">DASHBOARD item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminMain" datatype="html">
                <source>MAIN</source>
                <target>MAIN</target>
                <note priority="1" from="description">MAIN item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminAdministrator" datatype="html">
                <source>ADMINISTRATOR</source>
                <target>ADMINISTRATOR</target>
                <note priority="1" from="description">ADMINISTRATOR item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminCertificate" datatype="html">
                <source>CERTIFICATE</source>
                <target>ZERTIFIKAT</target>
                <note priority="1" from="description">CERTIFICATE item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminUsersGroups" datatype="html">
                <source>USERS AND GROUPS</source>
                <target>BENUTZER UND GRUPPEN</target>
                <note priority="1" from="description">USERS AND GROUPS item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminApplications" datatype="html">
                <source>APPLICATIONS</source>
                <target>ANWENDUNGEN</target>
                <note priority="1" from="description">APPLICATIONS item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminLicensing" datatype="html">
                <source>LICENSING</source>
                <target>LIZENZIERUNG</target>
                <note priority="1" from="description">LICENSING item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminManagement" datatype="html">
                <source>MANAGEMENT</source>
                <target>MANAGEMENT</target>
                <note priority="1" from="description">MANAGEMENT item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminBackupRestore" datatype="html">
                <source>BACKUP AND RESTORE</source>
                <target>SICHERN UND WIEDERHERSTELLEN</target>
                <note priority="1" from="description">BACKUP AND RESTORE</note>
            </trans-unit>
            <trans-unit id="AdminLogging" datatype="html">
                <source>LOGGING</source>
                <target>LOGGING</target>
                <note priority="1" from="description">LOGGING item on left navigation pane</note>
            </trans-unit>
            <trans-unit id="AdminEditExisting" datatype="html">
                <source>EDIT EXISTING</source>
                <target>BESTEHENDE BEARBEITEN</target>
                <note priority="1" from="description">EDIT EXISTING button for USERS AND GROUPS</note>
            </trans-unit>
            <trans-unit id="AdminCreateNew" datatype="html">
                <source>CREATE NEW</source>
                <target>NEUE ERSTELLEN</target>
                <note priority="1" from="description">CREATE NEW button for USERS AND GROUPS</note>
            </trans-unit>
            <trans-unit id="AdminAllocate" datatype="html">
                <source>ALLOCATE</source>
                <target>ZUWEISEN</target>
                <note priority="1" from="description">ALLOCATE button for USERS AND GROUPS</note>
            </trans-unit>
            <trans-unit id="AdminLocalUser" datatype="html">
                <source>Local User</source>
                <target>Lokaler Benutzer</target>
                <note priority="1" from="description">LOCAL USER button for USERS AND GROUPS::EDIT EXISTING</note>
            </trans-unit>
            <trans-unit id="AdminAuthenticationInstance" datatype="html">
                <source>Authentication Instance</source>
                <target>Authentifizierungsinstanz</target>
                <note priority="1" from="description">AUTHENTICATION INSTANCE button for USERS AND GROUPS::EDIT EXISTING</note>
            </trans-unit>
            <trans-unit id="AdminAuthenticationType" datatype="html">
                <source>Authentication type</source>
                <target>Authentifizierungsart</target>
                <note priority="1" from="description">AuthenticationType caption for UsersAndGroups::EditExisting::LocalUser</note>
            </trans-unit>
            <trans-unit id="AdminChangePassword" datatype="html">
                <source>1. Change Password</source>
                <target>1. Passwort ändern</target>
                <note priority="1" from="description">ChangePassword button for UsersAndGroups::EditExisting::LocalUser</note>
            </trans-unit>
            <trans-unit id="AdminSelect" datatype="html">
                <source>Select</source>
                <target>Wählen</target>
                <note priority="1" from="description">Select option for drop-down menus</note>
            </trans-unit>
            <trans-unit id="AdminUsers" datatype="html">
                <source>Users</source>
                <target>Benutzer</target>
                <note priority="1" from="description">Users option for drop-down menus</note>
            </trans-unit>
            <trans-unit id="AdminPassword" datatype="html">
                <source>Password</source>
                <target>Passwort</target>
                <note priority="1" from="description">Password label for UsersAndGroups::EditExisting::LocalUser</note>
            </trans-unit>
            <trans-unit id="AdminSubmitUppercase" datatype="html">
                <source>SUBMIT</source>
                <target>BESTÄTIGEN</target>
                <note priority="1" from="description">SUBMIT button</note>
            </trans-unit>
            <trans-unit id="AdminDeleteUser" datatype="html">
                <source>2. Delete User</source>
                <target>2. Benutzer löschen</target>
                <note priority="1" from="description">DeleteUser button for UsersAndGroups::EditExisting::LocalUser</note>
            </trans-unit>
            <trans-unit id="AdminDeleteUppercase" datatype="html">
                <source>DELETE</source>
                <target>LÖSCHEN</target>
                <note priority="1" from="description">DELETE button</note>
            </trans-unit>
            <trans-unit id="AdminEditInstance" datatype="html">
                <source>1. Edit Instance</source>
                <target>1. Instanz bearbeiten</target>
                <note priority="1" from="description">EditInstance button for UsersAndGroups::EditExisting::AuthenticationInstance</note>
            </trans-unit>
            <trans-unit id="AdminDeleteInstance" datatype="html">
                <source>2. Delete Instance</source>
                <target>2. Instanz löschen</target>
                <note priority="1" from="description">DeleteAuthenticationInstance button for UsersAndGroups::EditExisting::AuthenticationInstance</note>
            </trans-unit>
            <trans-unit id="AdminGeneralProperties" datatype="html">
                <source>General Properties</source>
                <target>Allgemeine Eigenschaften</target>
                <note priority="1" from="description">GeneralProperties button for UsersAndGroups::CreateNew</note>
            </trans-unit>
            <trans-unit id="AdminPermissionSettings" datatype="html">
                <source>Permission Settings</source>
                <target>Berechtigungseinstellungen</target>
                <note priority="1" from="description">PermissionSettings button for UsersAndGroups::CreateNew</note>
            </trans-unit>
            <trans-unit id="AdminAdvanced" datatype="html">
                <source>Advanced</source>
                <target>Erweitert</target>
                <note priority="1" from="description">Advanced button for UsersAndGroups::CreateNew</note>
            </trans-unit>
            <trans-unit id="AdminDefaultInstance" datatype="html">
                <source>2. Default Authentication Instance</source>
                <target>2. Standardauthentifizierungsinstanz</target>
                <note priority="1" from="description">DefaultAuthenticationInstance button for UsersAndGroups::CreateNew::GeneralProperties</note>
            </trans-unit>
            <trans-unit id="AdminCreateUser" datatype="html">
                <source>3. Create User</source>
                <target>3. Benutzer erstellen</target>
                <note priority="1" from="description">CreateUser button for UsersAndGroups::CreateNew::GeneralProperties</note>
            </trans-unit>
            <trans-unit id="AdminCreateGroup" datatype="html">
                <source>4. Create Group</source>
                <target>4. Gruppe erstellen</target>
                <note priority="1" from="description">CreateGroup button for UsersAndGroups::CreateNew::GeneralProperties</note>
            </trans-unit>
            <trans-unit id="AdminGroupName" datatype="html">
                <source>Group Name</source>
                <target>Gruppenname</target>
                <note priority="1" from="description">GroupName caption</note>
            </trans-unit>
            <trans-unit id="AdminReturnHomeUppercase" datatype="html">
                <source>RETURN TO HOME</source>
                <target>ZUR STARTSEITE</target>
                <note priority="1" from="description">ReturnToHome button</note>
            </trans-unit>
            <trans-unit id="AdminContinueUppercase" datatype="html">
                <source>CONTINUE</source>
                <target>FORTSETZEN</target>
                <note priority="1" from="description">CONTINUE button</note>
            </trans-unit>
            <trans-unit id="AdminCreateInstance" datatype="html">
                <source>1. Create Instance</source>
                <target>1. Instanz erstellen</target>
                <note priority="1" from="description">CreateInstance button for UsersAndGroups::CreateNew::GeneralProperties</note>
            </trans-unit>
            <trans-unit id="AdminAuthInstanceName" datatype="html">
                <source>Authentication Instance Name</source>
                <target>Name der Authentifizierungsinstanz</target>
                <note priority="1" from="description">AuthenticationInstanceName caption</note>
            </trans-unit>
            <trans-unit id="AdminAuthInstanceDesc" datatype="html">
                <source>Authentication Instance Description</source>
                <target>Authentifizierungsinstanz Beschreibung</target>
                <note priority="1" from="description">AuthenticationInstanceDescription caption</note>
            </trans-unit>
            <trans-unit id="AdminUserManagement" datatype="html">
                <source>User Management</source>
                <target>Benutzerverwaltung</target>
                <note priority="1" from="description">UserManagement caption</note>
            </trans-unit>
            <trans-unit id="AdminGroupManagement" datatype="html">
                <source>Group Management</source>
                <target>Gruppenmanagement</target>
                <note priority="1" from="description">GroupManagement caption</note>
            </trans-unit>
            <trans-unit id="AdminAssignGroupsUppercase" datatype="html">
                <source>ASSIGN GROUPS</source>
                <target>GRUPPEN ZUWEISEN</target>
                <note priority="1" from="description">AssignGroups button</note>
            </trans-unit>
            <trans-unit id="AdminUsername" datatype="html">
                <source>Username</source>
                <target>Benutzername</target>
                <note priority="1" from="description">Username table head</note>
            </trans-unit>
            <trans-unit id="AdminGroups" datatype="html">
                <source>Groups</source>
                <target>Gruppen</target>
                <note priority="1" from="description">Groups table head</note>
            </trans-unit>
            <trans-unit id="AdminManageUserGroup" datatype="html">
                <source>Manage Users Groups</source>
                <target>Verwalten von Benutzergruppen</target>
                <note priority="1" from="description">ManageUsersGroups</note>
            </trans-unit>
            <trans-unit id="AdminJoinSelectedGroup" datatype="html">
                <source>Join Selected Group</source>
                <target>Einer ausgewählten Gruppe beitreten</target>
                <note priority="1" from="description">JoinSelectedGroup button</note>
            </trans-unit>
            <trans-unit id="AdminUserGroup" datatype="html">
                <source>Groups the user belongs to</source>
                <target>Groupes auxquels l utilisateur appartient</target>
                <note priority="1" from="description">Caption for the group the user belongs to</note>
            </trans-unit>
            <trans-unit id="AdminGroupRemove" datatype="html">
                <source>Remove From Group</source>
                <target>Aus der Gruppe entfernen</target>
                <note priority="1" from="description">RemoveFromGroup button</note>
            </trans-unit>
            <trans-unit id="AdminOK" datatype="html">
                <source>OK</source>
                <target>OK</target>
                <note priority="1" from="description">OK button</note>
            </trans-unit>
            <trans-unit id="AdminUserCount" datatype="html">
                <source>No of users</source>
                <target>Anzahl der Nutzer</target>
                <note priority="1" from="description">NoOfUsers table header</note>
            </trans-unit>
            <trans-unit id="AdminActions" datatype="html">
                <source>Actions</source>
                <target>Aktionen</target>
                <note priority="1" from="description">Actions table header</note>
            </trans-unit>
            <trans-unit id="AdminEditMembers" datatype="html">
                <source>Edit Members</source>
                <target>Mitglieder bearbeiten</target>
                <note priority="1" from="description">EditMembers caption</note>
            </trans-unit>
            <trans-unit id="AdminDelete" datatype="html">
                <source>Delete</source>
                <target>Löschen</target>
                <note priority="1" from="description">Lowercase delete caption</note>
            </trans-unit>
            <trans-unit id="AdminPickUser" datatype="html">
                <source>Pick Users</source>
                <target>Nutzer auswählen</target>
                <note priority="1" from="description">PickUsers caption</note>
            </trans-unit>
            <trans-unit id="AdminCancel" datatype="html">
                <source>Cancel</source>
                <target>Stornieren</target>
                <note priority="1" from="description">Cancel button</note>
            </trans-unit>
            <trans-unit id="AdminBackToGroups" datatype="html">
                <source>Back to groups</source>
                <target>Zurück zu Gruppen</target>
                <note priority="1" from="description">BackToGroups button</note>
            </trans-unit>
            <trans-unit id="AdminGroupMembers" datatype="html">
                <source>Group Member(s)</source>
                <target>Gruppenmitglied(er)</target>
                <note priority="1" from="description">GroupMembers caption</note>
            </trans-unit>
            <trans-unit id="AdminRemoveSelected" datatype="html">
                <source>Remove selected user</source>
                <target>Ausgewählten Benutzer entfernen</target>
                <note priority="1" from="description">RemoveSelectedUser button</note>
            </trans-unit>
            <trans-unit id="AdminAddMember" datatype="html">
                <source>Add members to selected group</source>
                <target>Mitglieder zur ausgewählten Gruppe hinzufügen</target>
                <note priority="1" from="description">AddMembers button</note>
            </trans-unit>
            <trans-unit id="AdminAddUser" datatype="html">
                <source>Add selected user</source>
                <target>Ausgewählten Benutzer hinzufügen</target>
                <note priority="1" from="description">AddUser button</note>
            </trans-unit>
            <trans-unit id="AdminSelectDates" datatype="html">
                <source>Select the dates from below to download the logs</source>
                <target>Wählen Sie die Daten von unten aus, um die Protokolle herunterzuladen</target>
                <note priority="1" from="description">SelectDates caption for logs</note>
            </trans-unit>
            <trans-unit id="AdminStartDate" datatype="html">
                <source>Start Date</source>
                <target>Anfangsdatum</target>
                <note priority="1" from="description">StartDate caption for loggign</note>
            </trans-unit>
            <trans-unit id="AdminEndDate" datatype="html">
                <source>End Date</source>
                <target>Enddatum</target>
                <note priority="1" from="description">EndDate caption for logging</note>
            </trans-unit>
            <trans-unit id="AdminAllocateLowercase" datatype="html">
                <source>Allocate</source>
                <target>Zuweisen</target>
                <note priority="1" from="description">Allocate caption in lowercase</note>
            </trans-unit>
            <trans-unit id="AdminLicenseGroups" datatype="html">
                <source>License Groups</source>
                <target>Lizenzgruppen</target>
                <note priority="1" from="description">LicenseGroups table header</note>
            </trans-unit>
            <trans-unit id="AdminLicenseInfo" datatype="html">
                <source>View License Info</source>
                <target>Lizenzinformationen anzeigen</target>
                <note priority="1" from="description">ViewLicenseInfo tag</note>
            </trans-unit>
            <trans-unit id="AdminLicenseUserCount" datatype="html">
                <source>Licensed User Count</source>
                <target>Anzahl der lizenzierten Benutzer</target>
                <note priority="1" from="description">LicenseUserCount table header</note>
            </trans-unit>
            <trans-unit id="AdminMaxUser" datatype="html">
                <source>Max Allowed Users</source>
                <target>Maximal zulässige Benutzer</target>
                <note priority="1" from="description">MaxUsers for license</note>
            </trans-unit>
            <trans-unit id="AdminAssignLicenseUppercase" datatype="html">
                <source>ASSIGN LICENSE</source>
                <target>LIZENZ ZUWEISEN</target>
                <note priority="1" from="description">AssignLicense button</note>
            </trans-unit>
            <trans-unit id="AdminLicenseGroup" datatype="html">
                <source>License Group</source>
                <target>Lizenzgruppe</target>
                <note priority="1" from="description">LicenseGroup table head</note>
            </trans-unit>
            <trans-unit id="AdminManageLicenseGroup" datatype="html">
                <source>Manage Users License Groups</source>
                <target>Verwalten von Benutzerlizenzgruppen</target>
                <note priority="1" from="description">ManageUsersLicenseGroups header</note>
            </trans-unit>
            <trans-unit id="AdminLicenseCount" datatype="html">
                <source>License Count</source>
                <target>Lizenzanzahl</target>
                <note priority="1" from="description">LicenseCount header</note>
            </trans-unit>
            <trans-unit id="AdminActiveAuthInstance" datatype="html">
                <source>Active Authentication Instance</source>
                <target>Aktive Authentifizierungsinstanz</target>
                <note priority="1" from="description">ActiveAuthInstance header</note>
            </trans-unit>
            <trans-unit id="AdminAuthTypes" datatype="html">
                <source>Authentication Types</source>
                <target>Authentifizierungstypen</target>
                <note priority="1" from="description">AuthenticationTypes header</note>
            </trans-unit>
            <trans-unit id="AdminNewCertUppercase" datatype="html">
                <source>NEW CERTIFICATE</source>
                <target>NEUES ZERTIFIKAT</target>
                <note priority="1" from="description">NewCertificate link</note>
            </trans-unit>
            <trans-unit id="AdminServerCertUppercase" datatype="html">
                <source>SERVER CERTIFICATE</source>
                <target>SERVER ZERTIFIKAT</target>
                <note priority="1" from="description">ServerCertificate link</note>
            </trans-unit>
            <trans-unit id="AdminExistingCertUppercase" datatype="html">
                <source>EXISTING CERTIFICATE</source>
                <target>BESTEHENDES ZERTIFIKAT</target>
                <note priority="1" from="description">ExistingCertificate link</note>
            </trans-unit>
            <trans-unit id="AdminSelfCertUppercase" datatype="html">
                <source>SELF-SIGNED CERTIFICATE</source>
                <target>SELBSTSIGNIERTES ZERTIFIKAT</target>
                <note priority="1" from="description">SelfSignedCertificate link</note>
            </trans-unit>
            <trans-unit id="AdminDownloadKey" datatype="html">
                <source>Download Server key and Certificate</source>
                <target>Serverschlüssel und Zertifikat herunterladen</target>
                <note priority="1" from="description">DownloadKeyAndCertificifate header</note>
            </trans-unit>
            <trans-unit id="AdminPrivateKey" datatype="html">
                <source>Private Key:</source>
                <target>Privater Schlüssel:</target>
                <note priority="1" from="description">PrivateKey label caption</note>
            </trans-unit>
            <trans-unit id="AdminCert" datatype="html">
                <source>Certificate:</source>
                <target>Zertifikat:</target>
                <note priority="1" from="description">Certificate label caption</note>
            </trans-unit>
            <trans-unit id="AdminUploadKey" datatype="html">
                <source>Upload Server key and Certificate</source>
                <target>Serverschlüssel und Zertifikat hochladen</target>
                <note priority="1" from="description">UploadKeyAndCertificate header</note>
            </trans-unit>
            <trans-unit id="AdminCountry" datatype="html">
                <source>Country</source>
                <target>Land</target>
                <note priority="1" from="description">Country caption</note>
            </trans-unit>
            <trans-unit id="AdminState" datatype="html">
                <source>State</source>
                <target>Bundesland</target>
                <note priority="1" from="description">State label</note>
            </trans-unit>
            <trans-unit id="AdminCity" datatype="html">
                <source>City</source>
                <target>Stadt</target>
                <note priority="1" from="description">City caption</note>
            </trans-unit>
            <trans-unit id="AdminOrganisation" datatype="html">
                <source>Organisation</source>
                <target>Organisation</target>
                <note priority="1" from="description">Organisation caption</note>
            </trans-unit>
            <trans-unit id="AdminOrganisationDept" datatype="html">
                <source>Organisation Unit/Department</source>
                <target>Organisationseinheit / Abteilung</target>
                <note priority="1" from="description">OrganisationUnit/Department caption</note>
            </trans-unit>
            <trans-unit id="AdminCommonName" datatype="html">
                <source>Common Name</source>
                <target>Allgemeiner Name</target>
                <note priority="1" from="description">CommonName caption</note>
            </trans-unit>
            <trans-unit id="AdminEmail" datatype="html">
                <source>Email</source>
                <target>Email</target>
                <note priority="1" from="description">Email caption</note>
            </trans-unit>
            <trans-unit id="AdminBackToCSR" datatype="html">
                <source>Back to Generate CSR</source>
                <target>Zurück zum Generieren der CSR</target>
                <note priority="1" from="description">BacktoGenerateCSR button</note>
            </trans-unit>
            <trans-unit id="AdminCopyCSR" datatype="html">
                <source>Copy the below CSR to generate certificate</source>
                <target>Kopieren Sie die folgende CSR, um ein Zertifikat zu generieren</target>
                <note priority="1" from="description">CopyBelowCSR caption</note>
            </trans-unit>
            <trans-unit id="AdminDomain" datatype="html">
                <source>Domain</source>
                <target>Domain</target>
                <note priority="1" from="description">Domain caption</note>
            </trans-unit>
            <trans-unit id="AdminCreateUppercase" datatype="html">
                <source>CREATE</source>
                <target>ERSTELLEN</target>
                <note priority="1" from="description">Create caption</note>
            </trans-unit>
            <trans-unit id="AdminAddCAUppercase" datatype="html">
                <source>ADD NEW CA</source>
                <target>NEUE CA HINZUFÜGEN</target>
                <note priority="1" from="description">AddNewCA button</note>
            </trans-unit>
            <trans-unit id="AdminProviderAlias" datatype="html">
                <source>Certificate Provider Alias</source>
                <target>Zertifikatanbieter-Alias</target>
                <note priority="1" from="description">CertificateProviderAlias table header</note>
            </trans-unit>
            <trans-unit id="AdminAddCACert" datatype="html">
                <source>Add CA Certificate</source>
                <target>CA-Zertifikat hinzufügen</target>
                <note priority="1" from="description">AddCACert header</note>
            </trans-unit>
            <trans-unit id="AdminBackCertProvider" datatype="html">
                <source>Back to Certificate Provider</source>
                <target>Zurück zum Zertifikatanbieter</target>
                <note priority="1" from="description">BackToCertProvider header</note>
            </trans-unit>
            <trans-unit id="AdminAppType" datatype="html">
                <source>Application type</source>
                <target>Anwendungstyp</target>
                <note priority="1" from="description">ApplicationType caption</note>
            </trans-unit>
            <trans-unit id="AdminAppInstance" datatype="html">
                <source>Application instance</source>
                <target>Anwendungsinstanz</target>
                <note priority="1" from="description">ApplicationInstance caption</note>
            </trans-unit>
            <trans-unit id="AdminAppInstanceName" datatype="html">
                <source>Application Instance Name</source>
                <target>Anwendungsinstanzname</target>
                <note priority="1" from="description">ApplicationInstanceName caption</note>
            </trans-unit>
            <trans-unit id="AdminAppInstanceDesc" datatype="html">
                <source>Application Instance Description</source>
                <target>Anwendungsinstanz Beschreibung</target>
                <note priority="1" from="description">AppInstanceDescription caption</note>
            </trans-unit>
            <trans-unit id="AdminUserAppManagement" datatype="html">
                <source>User&App Management</source>
                <target>Nutzer- und Applikations-Verwaltung</target>
                <note priority="1" from="description">UserAppManagement link</note>
            </trans-unit>
            <trans-unit id="AdminAppManagement" datatype="html">
                <source>Application Management</source>
                <target>Applikations-Verwaltung</target>
                <note priority="1" from="description">AppManagement link</note>
            </trans-unit>
            <trans-unit id="AdminGroupAppManagement" datatype="html">
                <source>Group&App Management</source>
                <target>Gruppen- und Applikations-Verwaltung</target>
                <note priority="1" from="description">GroupAppManagement link</note>
            </trans-unit>
            <trans-unit id="AdminAssignApp" datatype="html">
                <source>ASSIGN APPLICATION</source>
                <target>ANWENDUNG ANWENDEN</target>
                <note priority="1" from="description">AssignApplication button</note>
            </trans-unit>
            <trans-unit id="AdminApps" datatype="html">
                <source>Applications</source>
                <target>Anwendungen</target>
                <note priority="1" from="description">Apps table header</note>
            </trans-unit>
            <trans-unit id="AdminManageUserApps" datatype="html">
                <source>Manage Users Apps</source>
                <target>Benutzer-Applikationen verwalten</target>
                <note priority="1" from="description">ManageUsersApps header</note>
            </trans-unit>
            <trans-unit id="AdminMapSelectedApp" datatype="html">
                <source>Map Selected App</source>
                <target>Karte Ausgewählte App</target>
                <note priority="1" from="description">mapSelectedApp button</note>
            </trans-unit>
            <trans-unit id="AdminAppsMapped" datatype="html">
                <source>Apps mapped to user</source>
                <target>Dem Nutzer zugeordnete Apps</target>
                <note priority="1" from="description">AppsMappedToUser caption</note>
            </trans-unit>
            <trans-unit id="AdminManageGroupApps" datatype="html">
                <source>Manage Groups Apps</source>
                <target>Applikationen für Gruppen verwalten</target>
                <note priority="1" from="description">ManageGroupsApps header</note>
            </trans-unit>
            <trans-unit id="AdminAppsMappedGroup" datatype="html">
                <source>Apps mapped to group</source>
                <target>Applikationen der Gruppe</target>
                <note priority="1" from="description">AppsMappedToGroup caption</note>
            </trans-unit>
            <trans-unit id="AdminUnmap" datatype="html">
                <source>UnMap Selected App</source>
                <target>Ausgewählte App entfernen</target>
                <note priority="1" from="description">UnMapSelected app button</note>
            </trans-unit>
            <trans-unit id="AdminAppName" datatype="html">
                <source>App Name</source>
                <target>Applikations-Name</target>
                <note priority="1" from="description">AppName table header</note>
            </trans-unit>
            <trans-unit id="AdminAddMembersApp" datatype="html">
                <source>Add members to selected app</source>
                <target>Mitglieder zur ausgewählten Applikation hinzufügen</target>
                <note priority="1" from="description">AddMembersToSelectedApp caption</note>
            </trans-unit>
            <trans-unit id="AdminCreateCert" datatype="html">
                <source>Create Certificate</source>
                <target>Zertifikat erstellen</target>
                <note priority="1" from="description">CreateCertificate caption</note>
            </trans-unit>
            <trans-unit id="AdminGenerateCSR" datatype="html">
                <source>Generate CSR</source>
                <target>CSR generieren</target>
                <note priority="1" from="description">GenerateCSR caption</note>
            </trans-unit>
            <trans-unit id="AdminCertProvider" datatype="html">
                <source>Certificate Provider</source>
                <target>Zertifikatsanbieter</target>
                <note priority="1" from="description">CertificateProvider caption</note>
            </trans-unit>
            <trans-unit id="AdminUploadCert" datatype="html">
                <source>Upload Certificate</source>
                <target>Zertifikat hochladen</target>
                <note priority="1" from="description">UploadCertificate caption</note>
            </trans-unit>
        </body>
    </file>
</xliff>`;