import { LoginFieldBase } from './loginfield-base';

export class TextboxLogin extends LoginFieldBase<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}