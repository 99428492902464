export class NextGenApplication{
    instanceId:number;
    instanceType:number;
    appTypeName:string;
    instanceName:string;
    instanceImage:string;
    accessType:string;
    accessString:string;
    status:boolean;

    constructor(instanceId:number,
                instanceType:number,
                appTypeName:string,
                instanceName:string,
                instanceImage:string,
                accessType:string,
                accessString:string,status:boolean){
                    this.instanceId = instanceId;
                    this.instanceType = instanceType;
                    this.appTypeName = appTypeName;
                    this.instanceName = instanceName;
                    this.instanceImage = instanceImage;
                    this.accessType = accessType;
                    this.accessString = accessString;
                    this.status = status;
    }
}

export class AppInstanceHeading {
  app_instance_id: number;
  app_type_id: number;
  app_instance_name: string;
  app_instance_descriptions: string;
  app_instance_image: string;
}


export class AppTypeField{
  app_type_field_id:number;
  app_type_type_id:number;
  app_type_field_name:string;
  app_type_field_label:string;
  app_type_field_description:string;
  app_type_field_default_value:string;
  app_type_field_field_type:string;
  app_type_field_type:string;
  app_type_field_order:number;
  app_type_field_section:number;
  app_type_field_editable:boolean;
  app_type_field_options:Array<AppTypeOption>;
}
export class AppTypeOption{
  option_id:number;
  option_field_id:number;
  option_name:string;
  option_label:string;
  option_description:string;
  option_type:string;
  option_value:string;
  option_order:number;
}

export class ExternalStorageInfo {

  public id: number;
  public type: string;
  public address: string;
  public path: string;
  public username: string;
  public password: string;
  public mounted: boolean;
  public active: boolean;

}
