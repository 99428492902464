<header>
        <table class="tableheader" style="width: 100%;">
            <tr style="background-color:#2C3643"> 
                <td style="width:85px;">
                    <img width="50" height="32" src="assets/images/netconnectlogo.png" style="padding: 5px; margin: 0 auto; display: block">
                </td>
                <td i18n="@@WelcomeBackExclamationMark" style="width:26%">
                    NetConnect
                </td>
                <td>
                <!--<button mat-button style="float: right; color: white;" [matMenuTriggerFor]="language">Language</button>-->
                    
                    <mat-menu #language="matMenu">
                        <button mat-menu-item (click)="selectLanguage('en-US')">EN</button>
                        <!-- <button mat-menu-item (click)="selectLanguage('de-DE')">DE</button> -->
                        <button mat-menu-item (click)="selectLanguage('fr-FR')">FR</button>
                    </mat-menu>
                </td>
            </tr>
        </table>
    </header>
<div class="container loginbody">
    <div class="login-box">
        <div class="login-logo">
            <img src="assets/images/netconnectlogoblue.png" style="width: 50px;" alt="NetConnect">
        </div>
        <div class="login-logo">
            <span i18n="@@CreateAdminUser">Create Admin User</span>
        </div>

        <mat-card (mouseenter)='mouseenter($event)' (mouseleave)="mouseleave($event)" class="login-box-body">
            <form method="post" #adminUser=ngForm (ngSubmit)="onSubmit(adminUser.value)" accept-charset="utf-8">
                <mat-form-field style="width: 100%;">
                    <input matInput type="text" name="adminname" required placeholder="Username" id="adminname" maxlength="80" size="30"
                    [ngModel]="createAdmin.adminname" #adminname="ngModel">
                </mat-form-field>
                <mat-form-field style="width: 100%;">
                    <input matInput type="password" name="password" required validateEqual="confirmPassword" reverse="true" placeholder="Password"
                        id="password" size="30" [ngModel]="createAdmin.password" #password="ngModel">
                </mat-form-field>
                <mat-form-field style="width: 100%;">
                    <input matInput type="password" name="confirmPassword" required validateEqual="password" reverse="false" placeholder="Retype Password"
                         id="confirmPassword" size="30" [ngModel]="createAdmin.confirmPassword" #confirmPassword="ngModel">
                    <small i18n="@@PasswordMismatch" [hidden]="confirmPassword.valid || (confirmPassword.pristine && !adminUser.submitted)" class="text-danger">Password mismatch</small>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit" name="submit" id="submit" [disabled]="!adminUser.form.valid" class="btn-block">
                    <span [hidden]="!showLoading" class="spinner">
                        <i class="glyphicon glyphicon-refresh spinning"></i>
                    </span>
                    <span i18n="@@Create">Create</span>
                </button>
                <br>
                <br>

                <mat-progress-bar *ngIf="showLoading" class="green-progress" mode="determinate" [value]="progressValue" max=100></mat-progress-bar>

                <div class="alert alert-success" *ngIf="successMessage">
                    <strong i18n="@@AdminUserCreated">Admin User is successfully created! </strong>
                    <p i18n="@@AdminUserRedirect">Redirecting you to the login page...</p>
                </div>
                <div class="alert alert-danger" *ngIf="showErr">
                    <p>{{errorMsg}}</p>
                </div>
            </form>
        </mat-card>
    </div>
</div>