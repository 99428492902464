export class LoginFieldBase<T>{
  value: T;
  id: number;
  name: string;
  label: string;
  description: string;
  type: string;
  order: number;
  autofocus: boolean;

  constructor(options: {
    value?: T,
    id?: number,
    name?: string,
    label?: string,
    description?: string,
    type?: string,
    order?: number,
  } = {}) {
    this.value = options.value;
    this.id = options.id;
    this.name = options.name || '';
    this.label = options.label || '';
    this.description = options.description || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.autofocus = (this.order === 1);
  }
}
