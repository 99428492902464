export const TRANSLATION_frFR = `<?xml version="1.0" encoding="UTF-8" ?>
<xliff version="1.2" xmlns="urn:oasis:names:tc:xliff:document:1.2">
	<file source-language="en" datatype="plaintext" original="ng2.template">
		<body>
			<trans-unit id="Actions" datatype="html">
				<source>Actions</source>
				<target>Actions</target>
				<note priority="1" from="description">Actions</note>
			</trans-unit>
			<trans-unit id="ActivateLicense" datatype="html">
				<source>Activate License</source>
				<target>Activer une licence</target>
				<note priority="1" from="description">Activate License</note>
			</trans-unit>
			<trans-unit id="ActivationHeading" datatype="html">
				<source>Please enter your activation key</source>
				<target>Merci d'entrer votre clé d'activation</target>
				<note priority="1" from="description">Please enter your activation key</note>
			</trans-unit>
			<trans-unit id="ActivationMessage" datatype="html">
				<source>Please enter an Activation Key</source>
				<target>Merci d'entrer une clé d'activation</target>
				<note priority="1" from="description">Please enter an Activation Key</note>
			</trans-unit>
			<trans-unit id="ActiveAuthenticationStage" datatype="html">
				<source>Active Authentication Stage</source>
				<target>Stade d'authentification actif</target>
				<note priority="1" from="description">Active Authentication Stage</note>
			</trans-unit>
			<trans-unit id="ActiveAuthStage" datatype="html">
				<source>Active Authentication Stage</source>
				<target>Stade d'authentification actif</target>
				<note priority="1" from="description">Active Authentication Stage</note>
			</trans-unit>
			<trans-unit id="ActiveCertificate" datatype="html">
				<source>Active Certificate</source>
				<target>Certificat actif</target>
				<note priority="1" from="description">Active Certificate</note>
			</trans-unit>
			<trans-unit id="ActiveLicenses" datatype="html">
				<source>Active Licenses</source>
				<target>License Active</target>
				<note priority="1" from="description">Active Licenses</note>
			</trans-unit>
			<trans-unit id="ActiveLicensing" datatype="html">
				<source>Active Licenses</source>
				<target>Licences actives</target>
				<note priority="1" from="description">Active Licenses</note>
			</trans-unit>
			<trans-unit id="ActiveUsers" datatype="html">
				<source>Active Users</source>
				<target>Utilisateurs actifs</target>
				<note priority="1" from="description">Active Users</note>
			</trans-unit>
			<trans-unit id="Add" datatype="html">
				<source>add</source>
				<target>Ajouter</target>
				<note priority="1" from="description">add</note>
			</trans-unit>
			<trans-unit id="AdminActions" datatype="html">
				<source>Actions</source>
				<target>Actions</target>
				<note priority="1" from="description">Actions table header</note>
			</trans-unit>
			<trans-unit id="AdminActiveAuthInstance" datatype="html">
				<source>Active Authentication Instance</source>
				<target>Instance d authentification active</target>
				<note priority="1" from="description">ActiveAuthInstance header</note>
			</trans-unit>
			<trans-unit id="AdminAddCACert" datatype="html">
				<source>Add CA Certificate</source>
				<target>Ajouter un certificat CA</target>
				<note priority="1" from="description">AddCACert header</note>
			</trans-unit>
			<trans-unit id="AdminAddCAUppercase" datatype="html">
				<source>ADD NEW CA</source>
				<target>AJOUTER UN NOUVEAU CA</target>
				<note priority="1" from="description">AddNewCA button</note>
			</trans-unit>
			<trans-unit id="AdminAddMember" datatype="html">
				<source>Add members to selected group</source>
				<target>Ajouter des membres au groupe sélectionné</target>
				<note priority="1" from="description">AddMembers button</note>
			</trans-unit>
			<trans-unit id="AdminAddMembersApp" datatype="html">
				<source>Add members to selected app</source>
				<target>Ajouter des membres à  l application sélectionnée</target>
				<note priority="1" from="description">AddMembersToSelectedApp caption</note>
			</trans-unit>
			<trans-unit id="AdminAddUser" datatype="html">
				<source>Add selected user</source>
				<target>Ajouter un utilisateur sélectionné</target>
				<note priority="1" from="description">AddUser button</note>
			</trans-unit>
			<trans-unit id="AdminAdministrator" datatype="html">
				<source>ADMINISTRATOR</source>
				<target>ADMINISTRATEUR</target>
				<note priority="1" from="description">ADMINISTRATOR item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdminAdvanced" datatype="html">
				<source>Advanced</source>
				<target>Avancé</target>
				<note priority="1" from="description">Advanced button for UsersAndGroups::CreateNew</note>
			</trans-unit>
			<trans-unit id="AdminAllocate" datatype="html">
				<source>ALLOCATE</source>
				<target>ALLOUER</target>
				<note priority="1" from="description">ALLOCATE button for USERS AND GROUPS</note>
			</trans-unit>
			<trans-unit id="AdminAllocateLowercase" datatype="html">
				<source>Allocate</source>
				<target>Allouer</target>
				<note priority="1" from="description">Allocate caption in lowercase</note>
			</trans-unit>
			<trans-unit id="AdminAppInstance" datatype="html">
				<source>Application instance</source>
				<target>Instance d application</target>
				<note priority="1" from="description">ApplicationInstance caption</note>
			</trans-unit>
			<trans-unit id="AdminAppInstanceDesc" datatype="html">
				<source>Application Instance Description</source>
				<target>Description de l instance d application</target>
				<note priority="1" from="description">AppInstanceDescription caption</note>
			</trans-unit>
			<trans-unit id="AdminAppInstanceName" datatype="html">
				<source>Application Instance Name</source>
				<target>Nom de l instance d application</target>
				<note priority="1" from="description">ApplicationInstanceName caption</note>
			</trans-unit>
			<trans-unit id="AdminApplications" datatype="html">
				<source>APPLICATIONS</source>
				<target>APPLICATIONS</target>
				<note priority="1" from="description">APPLICATIONS item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdminAppManagement" datatype="html">
				<source>Application Management</source>
				<target>Gestion des applications</target>
				<note priority="1" from="description">AppManagement link</note>
			</trans-unit>
			<trans-unit id="AdminAppName" datatype="html">
				<source>App Name</source>
				<target>Nom de l application</target>
				<note priority="1" from="description">AppName table header</note>
			</trans-unit>
			<trans-unit id="AdminApps" datatype="html">
				<source>Applications</source>
				<target>Applications</target>
				<note priority="1" from="description">Apps table header</note>
			</trans-unit>
			<trans-unit id="AdminAppsMapped" datatype="html">
				<source>Apps mapped to user</source>
				<target>Applications mappées à  l utilisateur</target>
				<note priority="1" from="description">AppsMappedToUser caption</note>
			</trans-unit>
			<trans-unit id="AdminAppsMappedGroup" datatype="html">
				<source>Apps mapped to group</source>
				<target>Applications mappées au groupe</target>
				<note priority="1" from="description">AppsMappedToGroup caption</note>
			</trans-unit>
			<trans-unit id="AdminAppType" datatype="html">
				<source>Application type</source>
				<target>Type d application</target>
				<note priority="1" from="description">ApplicationType caption</note>
			</trans-unit>
			<trans-unit id="AdminAssignApp" datatype="html">
				<source>ASSIGN APPLICATION</source>
				<target>ASSIGNER UNE APPLICATION</target>
				<note priority="1" from="description">AssignApplication button</note>
			</trans-unit>
			<trans-unit id="AdminAssignGroupsUppercase" datatype="html">
				<source>ASSIGN GROUPS</source>
				<target>AFFECTER DES GROUPES</target>
				<note priority="1" from="description">AssignGroups button</note>
			</trans-unit>
			<trans-unit id="AdminAssignLicenseUppercase" datatype="html">
				<source>ASSIGN LICENSE</source>
				<target>AFFECTATION DE LICENSE</target>
				<note priority="1" from="description">AssignLicense button</note>
			</trans-unit>
			<trans-unit id="AdminAuthenticationInstance" datatype="html">
				<source>Authentication Instance</source>
				<target>Instance d authentification</target>
				<note priority="1" from="description">AUTHENTICATION INSTANCE button for USERS AND GROUPS::EDIT EXISTING</note>
			</trans-unit>
			<trans-unit id="AdminAuthenticationType" datatype="html">
				<source>Authentication type</source>
				<target>Type d identification</target>
				<note priority="1" from="description">AuthenticationType caption for UsersAndGroups::EditExisting::LocalUser</note>
			</trans-unit>
			<trans-unit id="AdminAuthInstanceDesc" datatype="html">
				<source>Authentication Instance Description</source>
				<target>Description de l instance d authentification</target>
				<note priority="1" from="description">AuthenticationInstanceDescription caption</note>
			</trans-unit>
			<trans-unit id="AdminAuthInstanceName" datatype="html">
				<source>Authentication Instance Name</source>
				<target>Nom de l instance d authentification</target>
				<note priority="1" from="description">AuthenticationInstanceName caption</note>
			</trans-unit>
			<trans-unit id="AdminAuthTypes" datatype="html">
				<source>Authentication Types</source>
				<target>Types d authentification</target>
				<note priority="1" from="description">AuthenticationTypes header</note>
			</trans-unit>
			<trans-unit id="AdminBackCertProvider" datatype="html">
				<source>Back to Certificate Provider</source>
				<target>Retour au fournisseur de certificats</target>
				<note priority="1" from="description">BackToCertProvider header</note>
			</trans-unit>
			<trans-unit id="AdminBackToCSR" datatype="html">
				<source>Back to Generate CSR</source>
				<target>Retour à  Générer le CSR</target>
				<note priority="1" from="description">BacktoGenerateCSR button</note>
			</trans-unit>
			<trans-unit id="AdminBackToGroups" datatype="html">
				<source>Back to groups</source>
				<target>Retour aux groupes</target>
				<note priority="1" from="description">BackToGroups button</note>
			</trans-unit>
			<trans-unit id="AdminBackupRestore" datatype="html">
				<source>BACKUP AND RESTORE</source>
				<target>SAUVEGARDE ET RESTAURATION</target>
				<note priority="1" from="description">BACKUP AND RESTORE</note>
			</trans-unit>
			<trans-unit id="AdminCancel" datatype="html">
				<source>Cancel</source>
				<target>Annuler</target>
				<note priority="1" from="description">Cancel button</note>
			</trans-unit>
			<trans-unit id="AdminCert" datatype="html">
				<source>Certificate:</source>
				<target>Certificat:</target>
				<note priority="1" from="description">Certificate label caption</note>
			</trans-unit>
			<trans-unit id="AdminCertificate" datatype="html">
				<source>Certificate </source>
				<target>Certificat</target>
				<note priority="1" from="description">Certificate </note>
			</trans-unit>
			<trans-unit id="AdminCertProvider" datatype="html">
				<source>Certificate Provider</source>
				<target>Fournisseur de certificat</target>
				<note priority="1" from="description">CertificateProvider caption</note>
			</trans-unit>
			<trans-unit id="AdminChangePassword" datatype="html">
				<source>1. Change Password</source>
				<target>1. Changer le mot de passe</target>
				<note priority="1" from="description">ChangePassword button for UsersAndGroups::EditExisting::LocalUser</note>
			</trans-unit>
			<trans-unit id="AdminCity" datatype="html">
				<source>City</source>
				<target>Ville</target>
				<note priority="1" from="description">City caption</note>
			</trans-unit>
			<trans-unit id="AdminCommonName" datatype="html">
				<source>Common Name</source>
				<target>Nom commun</target>
				<note priority="1" from="description">CommonName caption</note>
			</trans-unit>
			<trans-unit id="AdminContinueUppercase" datatype="html">
				<source>CONTINUE</source>
				<target>CONTINUER</target>
				<note priority="1" from="description">CONTINUE button</note>
			</trans-unit>
			<trans-unit id="AdminCopyCSR" datatype="html">
				<source>Copy the below CSR to generate certificate</source>
				<target>Copiez le CSR ci-dessous pour générer le certificat</target>
				<note priority="1" from="description">CopyBelowCSR caption</note>
			</trans-unit>
			<trans-unit id="AdminCountry" datatype="html">
				<source>Country</source>
				<target>Pays</target>
				<note priority="1" from="description">Country caption</note>
			</trans-unit>
			<trans-unit id="AdminCreateCert" datatype="html">
				<source>Create Certificate</source>
				<target>Créer un certificat</target>
				<note priority="1" from="description">CreateCertificate caption</note>
			</trans-unit>
			<trans-unit id="AdminCreateGroup" datatype="html">
				<source>4. Create Group</source>
				<target>4. Créer un groupe</target>
				<note priority="1" from="description">CreateGroup button for UsersAndGroups::CreateNew::GeneralProperties</note>
			</trans-unit>
			<trans-unit id="AdminCreateInstance" datatype="html">
				<source>1. Create Instance</source>
				<target>1. Créer une instance</target>
				<note priority="1" from="description">CreateInstance button for UsersAndGroups::CreateNew::GeneralProperties</note>
			</trans-unit>
			<trans-unit id="AdminCreateNew" datatype="html">
				<source>CREATE NEW</source>
				<target>CREER NOUVEAU</target>
				<note priority="1" from="description">CREATE NEW button for USERS AND GROUPS</note>
			</trans-unit>
			<trans-unit id="AdminCreateUppercase" datatype="html">
				<source>CREATE</source>
				<target>CREER</target>
				<note priority="1" from="description">Create caption</note>
			</trans-unit>
			<trans-unit id="AdminCreateUser" datatype="html">
				<source>3. Create User</source>
				<target>3. Créer un utilisateur</target>
				<note priority="1" from="description">CreateUser button for UsersAndGroups::CreateNew::GeneralProperties</note>
			</trans-unit>
			<trans-unit id="AdminDashboard" datatype="html">
				<source>Dashboard</source>
				<target>Tableau de bord</target>
				<note priority="1" from="description">Dashboard</note>
			</trans-unit>
			<trans-unit id="AdminDefaultInstance" datatype="html">
				<source>2. Default Authentication Instance</source>
				<target>2. Instance d authentification par défaut</target>
				<note priority="1" from="description">DefaultAuthenticationInstance button for UsersAndGroups::CreateNew::GeneralProperties</note>
			</trans-unit>
			<trans-unit id="AdminDelete" datatype="html">
				<source>Delete</source>
				<target>Effacer</target>
				<note priority="1" from="description">Lowercase delete caption</note>
			</trans-unit>
			<trans-unit id="AdminDeleteInstance" datatype="html">
				<source>2. Delete Instance</source>
				<target>2. Supprimer l instance</target>
				<note priority="1" from="description">DeleteAuthenticationInstance button for UsersAndGroups::EditExisting::AuthenticationInstance</note>
			</trans-unit>
			<trans-unit id="AdminDeleteUppercase" datatype="html">
				<source>DELETE</source>
				<target>EFFACER</target>
				<note priority="1" from="description">DELETE button</note>
			</trans-unit>
			<trans-unit id="AdminDeleteUser" datatype="html">
				<source>2. Delete User</source>
				<target>2. Supprimer l utilisateur</target>
				<note priority="1" from="description">DeleteUser button for UsersAndGroups::EditExisting::LocalUser</note>
			</trans-unit>
			<trans-unit id="AdminDomain" datatype="html">
				<source>Domain</source>
				<target>Domaine</target>
				<note priority="1" from="description">Domain caption</note>
			</trans-unit>
			<trans-unit id="AdminDownloadKey" datatype="html">
				<source>Download Server key and Certificate</source>
				<target>Télécharger la clé du serveur et le certificat</target>
				<note priority="1" from="description">DownloadKeyAndCertificifate header</note>
			</trans-unit>
			<trans-unit id="AdminEditExisting" datatype="html">
				<source>EDIT EXISTING</source>
				<target>EDITER EXISTANT</target>
				<note priority="1" from="description">EDIT EXISTING button for USERS AND GROUPS</note>
			</trans-unit>
			<trans-unit id="AdminEditInstance" datatype="html">
				<source>1. Edit Instance</source>
				<target>1. Modifier l instance</target>
				<note priority="1" from="description">EditInstance button for UsersAndGroups::EditExisting::AuthenticationInstance</note>
			</trans-unit>
			<trans-unit id="AdminEditMembers" datatype="html">
				<source>Edit Members</source>
				<target>Modifier les membres</target>
				<note priority="1" from="description">EditMembers caption</note>
			</trans-unit>
			<trans-unit id="AdminEmail" datatype="html">
				<source>Email</source>
				<target>Email</target>
				<note priority="1" from="description">Email caption</note>
			</trans-unit>
			<trans-unit id="AdminEndDate" datatype="html">
				<source>End Date</source>
				<target>Date de fin</target>
				<note priority="1" from="description">EndDate caption for logging</note>
			</trans-unit>
			<trans-unit id="AdminExistingCertUppercase" datatype="html">
				<source>EXISTING CERTIFICATE</source>
				<target>CERTIFICAT EXISTANT</target>
				<note priority="1" from="description">ExistingCertificate link</note>
			</trans-unit>
			<trans-unit id="AdminGeneralProperties" datatype="html">
				<source>General Properties</source>
				<target>Propriétés générales</target>
				<note priority="1" from="description">GeneralProperties button for UsersAndGroups::CreateNew</note>
			</trans-unit>
			<trans-unit id="AdminGenerateCSR" datatype="html">
				<source>Generate CSR</source>
				<target>Générer le CSR</target>
				<note priority="1" from="description">GenerateCSR caption</note>
			</trans-unit>
			<trans-unit id="AdminGroupAppManagement" datatype="html">
				<source>Group&App Management</source>
				<target>Gestion des groupes et des applications</target>
				<note priority="1" from="description">GroupAppManagement link</note>
			</trans-unit>
			<trans-unit id="AdminGroupManagement" datatype="html">
				<source>Group Management</source>
				<target>Gestion de groupe</target>
				<note priority="1" from="description">GroupManagement caption</note>
			</trans-unit>
			<trans-unit id="AdminGroupMembers" datatype="html">
				<source>Group Member(s)</source>
				<target>Membre(s) du groupe</target>
				<note priority="1" from="description">GroupMembers caption</note>
			</trans-unit>
			<trans-unit id="AdminGroupName" datatype="html">
				<source>Group Name</source>
				<target>Nom de groupe</target>
				<note priority="1" from="description">GroupName caption</note>
			</trans-unit>
			<trans-unit id="AdminGroupRemove" datatype="html">
				<source>Remove From Group</source>
				<target>Supprimer du groupe</target>
				<note priority="1" from="description">RemoveFromGroup button</note>
			</trans-unit>
			<trans-unit id="AdminGroups" datatype="html">
				<source>Groups</source>
				<target>Groupes</target>
				<note priority="1" from="description">Groups table head</note>
			</trans-unit>
			<trans-unit id="AdminJoinSelectedGroup" datatype="html">
				<source>Join Selected Group</source>
				<target>Rejoindre le groupe sélectionné</target>
				<note priority="1" from="description">JoinSelectedGroup button</note>
			</trans-unit>
			<trans-unit id="AdminLicenseCount" datatype="html">
				<source>License Count</source>
				<target>Nombre de licences</target>
				<note priority="1" from="description">LicenseCount header</note>
			</trans-unit>
			<trans-unit id="AdminLicenseGroup" datatype="html">
				<source>License Group</source>
				<target>Groupe de licences</target>
				<note priority="1" from="description">LicenseGroup table head</note>
			</trans-unit>
			<trans-unit id="AdminLicenseGroups" datatype="html">
				<source>License Groups</source>
				<target>Groupes de licences</target>
				<note priority="1" from="description">LicenseGroups table header</note>
			</trans-unit>
			<trans-unit id="AdminLicenseInfo" datatype="html">
				<source>View License Info</source>
				<target>Afficher les informations de licence</target>
				<note priority="1" from="description">ViewLicenseInfo tag</note>
			</trans-unit>
			<trans-unit id="AdminLicenseUserCount" datatype="html">
				<source>Licensed User Count</source>
				<target>Nombre d utilisateurs sous licence</target>
				<note priority="1" from="description">LicenseUserCount table header</note>
			</trans-unit>
			<trans-unit id="AdminLicensing" datatype="html">
				<source>LICENSING</source>
				<target>LICENCES</target>
				<note priority="1" from="description">LICENSING item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdminLocalUser" datatype="html">
				<source>Local User</source>
				<target>Utilisateur local</target>
				<note priority="1" from="description">LOCAL USER button for USERS AND GROUPS::EDIT EXISTING</note>
			</trans-unit>
			<trans-unit id="AdminLogging" datatype="html">
				<source>LOGGING</source>
				<target>ENREGISTREMENT</target>
				<note priority="1" from="description">LOGGING item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdminMain" datatype="html">
				<source>MAIN</source>
				<target>PRINCIPAL</target>
				<note priority="1" from="description">MAIN item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdminManageGroupApps" datatype="html">
				<source>Manage Groups Apps</source>
				<target>Gérer les applications pour les groupes</target>
				<note priority="1" from="description">ManageGroupsApps header</note>
			</trans-unit>
			<trans-unit id="AdminManageLicenseGroup" datatype="html">
				<source>Manage Users License Groups</source>
				<target>Gérer les groupes de licences d utilisateurs</target>
				<note priority="1" from="description">ManageUsersLicenseGroups header</note>
			</trans-unit>
			<trans-unit id="AdminManagement" datatype="html">
				<source>MANAGEMENT</source>
				<target>GESTION</target>
				<note priority="1" from="description">MANAGEMENT item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdminManageUserApps" datatype="html">
				<source>Manage Users Apps</source>
				<target>Gérer les applications des utilisateurs</target>
				<note priority="1" from="description">ManageUsersApps header</note>
			</trans-unit>
			<trans-unit id="AdminManageUserGroup" datatype="html">
				<source>Manage Users Groups</source>
				<target>Gérer les groupes d utilisateurs</target>
				<note priority="1" from="description">ManageUsersGroups</note>
			</trans-unit>
			<trans-unit id="AdminMapSelectedApp" datatype="html">
				<source>Map Selected App</source>
				<target>App selectionnees</target>
				<note priority="1" from="description">mapSelectedApp button</note>
			</trans-unit>
			<trans-unit id="AdminMaxUser" datatype="html">
				<source>Max Allowed Users</source>
				<target>Utilisateurs autorisés maximum</target>
				<note priority="1" from="description">MaxUsers for license</note>
			</trans-unit>
			<trans-unit id="AdminNewCertUppercase" datatype="html">
				<source>NEW CERTIFICATE</source>
				<target>NOUVEAU CERTIFICAT</target>
				<note priority="1" from="description">NewCertificate link</note>
			</trans-unit>
			<trans-unit id="AdminOK" datatype="html">
				<source>OK</source>
				<target>OK</target>
				<note priority="1" from="description">OK button</note>
			</trans-unit>
			<trans-unit id="AdminOrganisation" datatype="html">
				<source>Organisation</source>
				<target>Organisation</target>
				<note priority="1" from="description">Organisation caption</note>
			</trans-unit>
			<trans-unit id="AdminOrganisationDept" datatype="html">
				<source>Organisation Unit/Department</source>
				<target>Unité / Département Organisation</target>
				<note priority="1" from="description">OrganisationUnit/Department caption</note>
			</trans-unit>
			<trans-unit id="Password" datatype="html">
				<source>Password</source>
				<target>Mot de passe</target>
				<note priority="1" from="description">Password label for UsersAndGroups::EditExisting::LocalUser</note>
			</trans-unit>
			<trans-unit id="AdminPassword" datatype="html">
				<source>Password</source>
				<target>Mot de passe</target>
				<note priority="1" from="description">Password label for UsersAndGroups::EditExisting::LocalUser</note>
			</trans-unit>
			<trans-unit id="AdminPermissionSettings" datatype="html">
				<source>Permission Settings</source>
				<target>Paramètres d autorisation</target>
				<note priority="1" from="description">PermissionSettings button for UsersAndGroups::CreateNew</note>
			</trans-unit>
			<trans-unit id="AdminPickUser" datatype="html">
				<source>Pick Users</source>
				<target>Choisir les utilisateurs</target>
				<note priority="1" from="description">PickUsers caption</note>
			</trans-unit>
			<trans-unit id="AdminPrivateKey" datatype="html">
				<source>Private Key:</source>
				<target>Clé privée:</target>
				<note priority="1" from="description">PrivateKey label caption</note>
			</trans-unit>
			<trans-unit id="AdminProviderAlias" datatype="html">
				<source>Certificate Provider Alias</source>
				<target>Alias du fournisseur de certificat</target>
				<note priority="1" from="description">CertificateProviderAlias table header</note>
			</trans-unit>
			<trans-unit id="AdminRemoveSelected" datatype="html">
				<source>Remove selected user</source>
				<target>Supprimer l utilisateur sélectionné</target>
				<note priority="1" from="description">RemoveSelectedUser button</note>
			</trans-unit>
			<trans-unit id="AdminReturnHomeUppercase" datatype="html">
				<source>RETURN TO HOME</source>
				<target>RETOURNER A LA PAGE PRINCIPALE</target>
				<note priority="1" from="description">ReturnToHome button</note>
			</trans-unit>
			<trans-unit id="AdminSelect" datatype="html">
				<source>Select</source>
				<target>Sélectionner</target>
				<note priority="1" from="description">Select option for drop-down menus</note>
			</trans-unit>
			<trans-unit id="AdminSelectDates" datatype="html">
				<source>Select a date range to extract historical logs</source>
				<target>Entrer une date fourchette de dates pour extraire les journaux</target>
				<note priority="1" from="description">Select a date range to extract historical logs</note>
			</trans-unit>
			<trans-unit id="AdminSelfCertUppercase" datatype="html">
				<source>SELF-SIGNED CERTIFICATE</source>
				<target>CERTIFICAT AUTO-SIGNE</target>
				<note priority="1" from="description">SelfSignedCertificate link</note>
			</trans-unit>
			<trans-unit id="AdminServerCertUppercase" datatype="html">
				<source>SERVER CERTIFICATE</source>
				<target>CERTIFICAT DE SERVEUR</target>
				<note priority="1" from="description">ServerCertificate link</note>
			</trans-unit>
			<trans-unit id="AdminStartDate" datatype="html">
				<source>Start Date</source>
				<target>Date de début</target>
				<note priority="1" from="description">StartDate caption for loggign</note>
			</trans-unit>
			<trans-unit id="AdminState" datatype="html">
				<source>State</source>
				<target>Etat</target>
				<note priority="1" from="description">State label</note>
			</trans-unit>
			<trans-unit id="AdminSubmitUppercase" datatype="html">
				<source>SUBMIT</source>
				<target>SOUMETTRE</target>
				<note priority="1" from="description">SUBMIT button</note>
			</trans-unit>
			<trans-unit id="AdminUnmap" datatype="html">
				<source>UnMap Selected App</source>
				<target>Annuler l application sélectionnée</target>
				<note priority="1" from="description">UnMapSelected app button</note>
			</trans-unit>
			<trans-unit id="AdminUploadCert" datatype="html">
				<source>Upload Certificate</source>
				<target>Télécharger le certificat</target>
				<note priority="1" from="description">UploadCertificate caption</note>
			</trans-unit>
			<trans-unit id="AdminUploadKey" datatype="html">
				<source>Upload Server key and Certificate</source>
				<target>Télécharger la clé du serveur et le certificat</target>
				<note priority="1" from="description">UploadKeyAndCertificate header</note>
			</trans-unit>
			<trans-unit id="AdminUserAppManagement" datatype="html">
				<source>User&App Management</source>
				<target>Gestion des utilisateurs et des applications</target>
				<note priority="1" from="description">UserAppManagement link</note>
			</trans-unit>
			<trans-unit id="AdminUserCount" datatype="html">
				<source>No of users</source>
				<target>Nombre d utilisateurs</target>
				<note priority="1" from="description">NoOfUsers table header</note>
			</trans-unit>
			<trans-unit id="AdminUserCreated" datatype="html">
				<source>Admin User is successfully created! </source>
				<target>Compte administrateur créé!</target>
				<note priority="1" from="description">Admin User is successfully created! </note>
			</trans-unit>
			<trans-unit id="AdminUserGroup" datatype="html">
				<source>Groups the user belongs to</source>
				<target>Groupes auxquels l utilisateur appartient</target>
				<note priority="1" from="description">Caption for the group the user belongs to</note>
			</trans-unit>
			<trans-unit id="AdminUserManagement" datatype="html">
				<source>User Management</source>
				<target>Gestion des utilisateurs</target>
				<note priority="1" from="description">UserManagement caption</note>
			</trans-unit>
			<trans-unit id="AdminUsername" datatype="html">
				<source>Username</source>
				<target>Nom d utilisateur</target>
				<note priority="1" from="description">Username table head</note>
			</trans-unit>
			<trans-unit id="AdminUserRedirect" datatype="html">
				<source>Redirecting you to the login page...</source>
				<target>Redirection vers la page de connexion</target>
				<note priority="1" from="description">Redirecting you to the login page...</note>
			</trans-unit>
			<trans-unit id="AdminUsers" datatype="html">
				<source>Users</source>
				<target>Utilisateurs</target>
				<note priority="1" from="description">Users option for drop-down menus</note>
			</trans-unit>
			<trans-unit id="AdminUsersGroups" datatype="html">
				<source>USERS AND GROUPS</source>
				<target>UTILISATEURS ET GROUPES</target>
				<note priority="1" from="description">USERS AND GROUPS item on left navigation pane</note>
			</trans-unit>
			<trans-unit id="AdvancedOptions" datatype="html">
				<source> 2.Advanced Options (Optional)</source>
				<target>Options avancées</target>
				<note priority="1" from="description"> 2.Advanced Options (Optional)</note>
			</trans-unit>
			<trans-unit id="AppInstanceName" datatype="html">
				<source>Please choose an application instance name</source>
				<target>Merci de sélectionner un nom d'instance d'application</target>
				<note priority="1" from="description">Please choose an application instance name</note>
			</trans-unit>
			<trans-unit id="Applications" datatype="html">
				<source>Applications</source>
				<target>Applications</target>
				<note priority="1" from="description">Applications</note>
			</trans-unit>
			<trans-unit id="Applicationsbygroups" datatype="html">
				<source>Applications by groups</source>
				<target>Applications par Groupes</target>
				<note priority="1" from="description">Applications by groups</note>
			</trans-unit>
			<trans-unit id="Applicationsbyusers" datatype="html">
				<source>Applications by users</source>
				<target>Applications par Utilisateurs</target>
				<note priority="1" from="description">Applications by users</note>
			</trans-unit>
			<trans-unit id="ApplicationsHeader" datatype="html">
				<source>Applications</source>
				<target>Applications</target>
				<note priority="1" from="description">Header for the Applications Section</note>
			</trans-unit>
			<trans-unit id="ApplicationsUsers" datatype="html">
				<source>Applications & Users </source>
				<target>Applications & Utilisateurs</target>
				<note priority="1" from="description">Applications & Users </note>
			</trans-unit>
			<trans-unit id="Assign Licenses" datatype="html">
				<source>Assign Licenses</source>
				<target>Assigner une licence</target>
				<note priority="1" from="description">Assign Licenses</note>
			</trans-unit>
			<trans-unit id="AssignLicensing" datatype="html">
				<source>Assign Licenses</source>
				<target>Assigner une licence</target>
				<note priority="1" from="description">Assign Licenses</note>
			</trans-unit>
			<trans-unit id="Authentication" datatype="html">
				<source>Authentication </source>
				<target>Authentification</target>
				<note priority="1" from="description">Authentication </note>
			</trans-unit>
			<trans-unit id="AuthenticationError" datatype="html">
				<source>Authentication Error!</source>
				<target>Erreur d'authentification !</target>
				<note priority="1" from="description">Authentication Error!</note>
			</trans-unit>
			<trans-unit id="AuthenticationErrorDetail" datatype="html">
				<source>Failed to authenticate user. Please contact your system administrator.</source>
				<target>Authentification impossible. Veuillez contacter votre administrateur.</target>
				<note priority="1" from="description">Failed to authenticate user. Please contact your system administrator.</note>
			</trans-unit>
			<trans-unit id="AuthenticationStages" datatype="html">
				<source>Authentication Stages</source>
				<target>Stades d'authentification</target>
				<note priority="1" from="description">Authentication Stages</note>
			</trans-unit>
			<trans-unit id="Autodiscover" datatype="html">
				<source>Autodiscover</source>
				<target>Auto-découverte</target>
				<note priority="1" from="description">Autodiscover</note>
			</trans-unit>
			<trans-unit id="AutoDiscoverErrorDomain" datatype="html">
				<source>Please enter a domain</source>
				<target>Entrer un domaine</target>
				<note priority="1" from="description">Please enter a domain</note>
			</trans-unit>
			<trans-unit id="AutoDiscoverErrorDomainEntry" datatype="html">
				<source>Please enter a domain first!</source>
				<target>Merci d'entrer un domaine!</target>
				<note priority="1" from="description">Please enter a domain first!</note>
			</trans-unit>
			<trans-unit id="AutoDiscoverErrorInstName" datatype="html">
				<source>Please choose an authentication instance name</source>
				<target>Merci de sélectionner un nom d'instance d'authentification</target>
				<note priority="1" from="description">Please choose an authentication instance name</note>
			</trans-unit>
			<trans-unit id="AvailableBackups" datatype="html">
				<source>AvailableBackups</source>
				<target>Sauvegardes disponibles</target>
				<note priority="1" from="description">AvailableBackups</note>
			</trans-unit>
			<trans-unit id="BackupRestore" datatype="html">
				<source>Backup & Restore</source>
				<target>Sauvegarde & Restoration</target>
				<note priority="1" from="description">Backup & Restore</note>
			</trans-unit>
			<trans-unit id="BasicInfo" datatype="html">
				<source> 1.Basic Information</source>
				<target>Informations de base</target>
				<note priority="1" from="description"> 1.Basic Information</note>
			</trans-unit>
			<trans-unit id="Cancel" datatype="html">
				<source>Cancel</source>
				<target>Annuler</target>
				<note priority="1" from="description">Cancel</note>
			</trans-unit>
			<trans-unit id="CancelUpgrade" datatype="html">
				<source>Not yet, cancel the upgrade</source>
				<target>Non, Annuler la mise a jour</target>
				<note priority="1" from="description">Not yet, cancel the upgrade</note>
			</trans-unit>
			<trans-unit id="Certificate" datatype="html">
				<source>Certificate</source>
				<target>Certificat</target>
				<note priority="1" from="description">Certificate</note>
			</trans-unit>
			<trans-unit id="CertificateDetails" datatype="html">
				<source>View Current Certificate Details</source>
				<target>Voir les détails du certificat</target>
				<note priority="1" from="description">View Current Certificate Details</note>
			</trans-unit>
			<trans-unit id="CheckNow" datatype="html">
				<source>Check Now</source>
				<target>Vérifier Maintenant</target>
				<note priority="1" from="description">Check Now</note>
			</trans-unit>
			<trans-unit id="ChooseAuthInst" datatype="html">
				<source>Please choose an authentication instance</source>
				<target>Merci de sélectionner une instance d'authentification</target>
				<note priority="1" from="description">Please choose an authentication instance</note>
			</trans-unit>
			<trans-unit id="City" datatype="html">
				<source>City</source>
				<target>Ville</target>
				<note priority="1" from="description">City</note>
			</trans-unit>
			<trans-unit id="Clipboard" datatype="html">
				<source>Clipboard</source>
				<target>Presse-papiers</target>
				<note priority="1" from="description">Clipboard</note>
			</trans-unit>
			<trans-unit id="CommonName" datatype="html">
				<source>Common Name</source>
				<target>Nom Commum</target>
				<note priority="1" from="description">Common Name</note>
			</trans-unit>
			<trans-unit id="ContactUsLink" datatype="html">
				<source>Contact Us</source>
				<target>Contactez-nous</target>
				<note priority="1" from="description">Link for the Contact formular</note>
			</trans-unit>
			<trans-unit id="Copy" datatype="html">
				<source>Copy</source>
				<target>Copier</target>
				<note priority="1" from="description">Copy</note>
			</trans-unit>
			<trans-unit id="Country" datatype="html">
				<source>Country</source>
				<target>Pays</target>
				<note priority="1" from="description">Country</note>
			</trans-unit>
			<trans-unit id="Create" datatype="html">
				<source>Create</source>
				<target>Créer</target>
				<note priority="1" from="description">Create</note>
			</trans-unit>
			<trans-unit id="CreateAdminUser" datatype="html">
				<source>Create Admin User</source>
				<target>Création du compte administrateur</target>
				<note priority="1" from="description">Create Admin User</note>
			</trans-unit>
			<trans-unit id="CreateGroup" datatype="html">
				<source>Create Group</source>
				<target>Créer un groupe</target>
				<note priority="1" from="description">Create Group</note>
			</trans-unit>
			<trans-unit id="CreateUser" datatype="html">
				<source>Create User</source>
				<target>Créer un utilisateur</target>
				<note priority="1" from="description">Create User</note>
			</trans-unit>
			<trans-unit id="CurrentVersion" datatype="html">
				<source>Current Version</source>
				<target>Version active</target>
				<note priority="1" from="description">Current Version</note>
			</trans-unit>
			<trans-unit id="Cut" datatype="html">
				<source>Cut</source>
				<target>Couper</target>
				<note priority="1" from="description">Cut</note>
			</trans-unit>
			<trans-unit id="Datedownloaded" datatype="html">
				<source>Date downloaded</source>
				<target>Date de téléchargement</target>
				<note priority="1" from="description">Date downloaded</note>
			</trans-unit>
			<trans-unit id="Delete" datatype="html">
				<source>Delete</source>
				<target>Supprimer</target>
				<note priority="1" from="description">Delete</note>
			</trans-unit>
			<trans-unit id="Description" datatype="html">
				<source>Description</source>
				<target>Description</target>
				<note priority="1" from="description">Description</note>
			</trans-unit>
			<trans-unit id="DNSSuffix" datatype="html">
				<source>DNS Suffix</source>
				<target>Suffixe DNS</target>
				<note priority="1" from="description">DNS Suffix</note>
			</trans-unit>
			<trans-unit id="Download" datatype="html">
				<source>Download</source>
				<target>Télécharger</target>
				<note priority="1" from="description">Download</note>
			</trans-unit>
			<trans-unit id="DownloadLatestBuild" datatype="html">
				<source>Download Latest Build</source>
				<target>Télécharger la dernière version</target>
				<note priority="1" from="description">Download Latest Build</note>
			</trans-unit>
			<trans-unit id="Duplicate" datatype="html">
				<source>Duplicate</source>
				<target>Dupliquer</target>
				<note priority="1" from="description">Duplicate</note>
			</trans-unit>
			<trans-unit id="Duplicate User" datatype="html">
				<source>Duplicate User</source>
				<target>Dupliquer l'utilisateur</target>
				<note priority="1" from="description">Duplicate User</note>
			</trans-unit>
			<trans-unit id="Email" datatype="html">
				<source>Email</source>
				<target>Email</target>
				<note priority="1" from="description">Email</note>
			</trans-unit>
			<trans-unit id="ErroAuthInstName" datatype="html">
				<source>Please choose an authentication instance name</source>
				<target>Merci de sélectionner un nom d'instance d'authentification</target>
				<note priority="1" from="description">Please choose an authentication instance name</note>
			</trans-unit>
			<trans-unit id="ErrorAppInst" datatype="html">
				<source>Please choose an application instance name</source>
				<target>Merci de sélectionner un nom d'instance d'application</target>
				<note priority="1" from="description">Please choose an application instance name</note>
			</trans-unit>
			<trans-unit id="ErrorAppInstName" datatype="html">
				<source>Please choose an application instance name</source>
				<target>Merci de sélectionner un nom d'instance d'application</target>
				<note priority="1" from="description">Please choose an application instance name</note>
			</trans-unit>
			<trans-unit id="ErrorAuthInstName" datatype="html">
				<source>Please choose an authentication instance name</source>
				<target>Merci de sélectionner un nom d'instance d'authentification</target>
				<note priority="1" from="description">Please choose an authentication instance name</note>
			</trans-unit>
			<trans-unit id="ExistingCertificate" datatype="html">
				<source>Import Existing Certificate</source>
				<target>Importer un certificat public</target>
				<note priority="1" from="description">Import Existing Certificate</note>
			</trans-unit>
			<trans-unit id="ExistingRootCert" datatype="html">
				<source>Existing Root Certificate</source>
				<target>Certificat racine Existant</target>
				<note priority="1" from="description">Existing Root Certificate</note>
			</trans-unit>
			<trans-unit id="ExpiryDate" datatype="html">
				<source>Expiry Date</source>
				<target>Date d'expiration</target>
				<note priority="1" from="description">Expiry Date</note>
			</trans-unit>
			<trans-unit id="Files" datatype="html">
				<source>Files:</source>
				<target>Fichiers :</target>
				<note priority="1" from="description">Files:</note>
			</trans-unit>
			<trans-unit id="FileSize" datatype="html">
				<source>File Size</source>
				<target>Taille du fichier</target>
				<note priority="1" from="description">File Size</note>
			</trans-unit>
			<trans-unit id="FileType" datatype="html">
				<source>File Type</source>
				<target>Type de fichier</target>
				<note priority="1" from="description">File Type</note>
			</trans-unit>
			<trans-unit id="General" datatype="html">
				<source>General</source>
				<target>Général</target>
				<note priority="1" from="description">General</note>
			</trans-unit>
			<trans-unit id="Generate" datatype="html">
				<source>Generate</source>
				<target>Générer</target>
				<note priority="1" from="description">Generate</note>
			</trans-unit>
			<trans-unit id="GenerateCsrHeading" datatype="html">
				<source>Creating your third party certificate requires you to fill out the below form, send the output to your certificate provider,  who will send you back your certificate. You can then apply this certificate in the "Upload Certificate" tab</source>
				<target>Création en cours</target>
				<note priority="1" from="description">Creating your third party certificate requires you to fill out the below form, send the output to your certificate provider,  who will send you back your certificate. You can then apply this certificate in the "Upload Certificate" tab</note>
			</trans-unit>
			<trans-unit id="GenerateLicense" datatype="html">
				<source>Generate License</source>
				<target>Générer une licence</target>
				<note priority="1" from="description">Generate License</note>
			</trans-unit>
			<trans-unit id="Group" datatype="html">
				<source>Group</source>
				<target>Groupe</target>
				<note priority="1" from="description">Group</note>
			</trans-unit>
			<trans-unit id="GroupNameError" datatype="html">
				<source> Groupname is required</source>
				<target>Erreur de nom de groupe</target>
				<note priority="1" from="description"> Groupname is required</note>
			</trans-unit>
			<trans-unit id="GroupnameRequired" datatype="html">
				<source> Groupname is required</source>
				<target>Nom de groupe requis</target>
				<note priority="1" from="description"> Groupname is required</note>
			</trans-unit>
			<trans-unit id="Groups" datatype="html">
				<source>Groups</source>
				<target>Groupes</target>
				<note priority="1" from="description">Groups</note>
			</trans-unit>
			<trans-unit id="HelpLink" datatype="html">
				<source>Help</source>
				<target>Aide</target>
				<note priority="1" from="description">Link to navigate to the Help page</note>
			</trans-unit>
			<trans-unit id="Id" datatype="html">
				<source>Id</source>
				<target>Id</target>
				<note priority="1" from="description">Id</note>
			</trans-unit>
			<trans-unit id="ImportExistinCert" datatype="html">
				<source>Import Existing Certificate and Private key</source>
				<target>Importer un certificat public et sa clé publique</target>
				<note priority="1" from="description">Import Existing Certificate and Private key</note>
			</trans-unit>
			<trans-unit id="ImportGroups" datatype="html">
				<source>Import Groups</source>
				<target>Importer des groupes</target>
				<note priority="1" from="description">Import Groups</note>
			</trans-unit>
			<trans-unit id="LastLogin" datatype="html">
				<source>Last Login</source>
				<target>Dernière Connexion</target>
				<note priority="1" from="description">Last Login</note>
			</trans-unit>
			<trans-unit id="License" datatype="html">
				<source>License</source>
				<target>licence</target>
				<note priority="1" from="description">License</note>
			</trans-unit>
			<trans-unit id="LicenseAgreement" datatype="html">
				<source>License Agreement</source>
				<target>Contrat de Licence</target>
				<note priority="1" from="description">License Agreement</note>
			</trans-unit>
			<trans-unit id="LicenseIntroduction" datatype="html">
				<source>Please read the following License Agreement. You must accept the terms
                    of this agreement before continuing the installation.</source>
				<target>Merci de lire attentivement le contrat de Licence. Vous devez accepter le contrat de licence pour continuer l'installation.</target>
				<note priority="1" from="description">Please read the following License Agreement. You must accept the terms
                    of this agreement before continuing the installation.</note>
			</trans-unit>
			<trans-unit id="LicenseOverview" datatype="html">
				<source>License Overview</source>
				<target>Aperçu des licences</target>
				<note priority="1" from="description">License Overview</note>
			</trans-unit>
			<trans-unit id="LicensePleaseAccept" datatype="html">
				<source>Please accept the agreement to continue</source>
				<target>Merci d'accepter le contrat de licence pour continuer</target>
				<note priority="1" from="description">Please accept the agreement to continue</note>
			</trans-unit>
			<trans-unit id="LicenseRefuse" datatype="html">
				<source>I do not accept the agreement</source>
				<target>Je refuse le contrat de licence</target>
				<note priority="1" from="description">I do not accept the agreement</note>
			</trans-unit>
			<trans-unit id="LicenseType" datatype="html">
				<source>License Type</source>
				<target>Type de licence</target>
				<note priority="1" from="description">License Type</note>
			</trans-unit>
			<trans-unit id="Licensing" datatype="html">
				<source>Licensing </source>
				<target>Licences</target>
				<note priority="1" from="description">Licensing </note>
			</trans-unit>
			<trans-unit id="LoadCert" datatype="html">
				<source>Load Certificate</source>
				<target>Télécharger le certificat</target>
				<note priority="1" from="description">Load Certificate</note>
			</trans-unit>
			<trans-unit id="LocalUserManagement" datatype="html">
				<source>Local User Management</source>
				<target>Gestion des utilisateurs locaux</target>
				<note priority="1" from="description">Local User Management</note>
			</trans-unit>
			<trans-unit id="Logging" datatype="html">
				<source>Logging</source>
				<target>Journaux</target>
				<note priority="1" from="description">Logging</note>
			</trans-unit>
			<trans-unit id="LoginIncorrectCredentials" datatype="html">
				<source>Please re-enter your Credentials.</source>
				<target>Merci de ré-entrer vos identifiants.</target>
				<note priority="1" from="description">Please re-enter your Credentials.</note>
			</trans-unit>
			<trans-unit id="LoginIncorrectCredentialsBold" datatype="html">
				<source>Username or password wrong!</source>
				<target>Mauvais utilisateur ou mot de passe!</target>
				<note priority="1" from="description">Username or password wrong!</note>
			</trans-unit>
			<trans-unit id="LoginUppercase" datatype="html">
				<source>LOGIN</source>
				<target>CONNEXION</target>
				<note priority="1" from="description">Login in uppercase</note>
			</trans-unit>
			<trans-unit id="LogStart" datatype="html">
				<source>Click start to begin log capture</source>
				<target>Cliquer sur Démarrer pour commencer la journalisation.</target>
				<note priority="1" from="description">Click start to begin log capture</note>
			</trans-unit>
			<trans-unit id="LYFDeleteCredentials" datatype="html">
				<source>Delete Credentials</source>
				<target>Effacer les identifiants</target>
				<note priority="1" from="description">Delete Credentials</note>
			</trans-unit>
			<trans-unit id="LyfDeleteFunction" datatype="html">
				<source>Delete</source>
				<target>Effacer</target>
				<note priority="1" from="description">The delete function in lyf</note>
			</trans-unit>
			<trans-unit id="LyfDirectoryHeader" datatype="html">
				<source>Directory</source>
				<target>Répertoire</target>
				<note priority="1" from="description">The DirectoryHeader in Lyf</note>
			</trans-unit>
			<trans-unit id="LyfDownloadFunction" datatype="html">
				<source>Download</source>
				<target>Télécharger</target>
				<note priority="1" from="description">The Lyf download function</note>
			</trans-unit>
			<trans-unit id="LyfFileHeader" datatype="html">
				<source>File</source>
				<target>Fichier</target>
				<note priority="1" from="description">Header for the File view</note>
			</trans-unit>
			<trans-unit id="LyfFilesFilter" datatype="html">
				<source>Files</source>
				<target>Fichiers</target>
				<note priority="1" from="description">The File Filter in Lyf</note>
			</trans-unit>
			<trans-unit id="LyfFilterAll" datatype="html">
				<source>All</source>
				<target>Tout</target>
				<note priority="1" from="description">The All Filter in Lyf</note>
			</trans-unit>
			<trans-unit id="LyfFolderItemDescription" datatype="html">
				<source>Folder</source>
				<target>Dossier</target>
				<note priority="1" from="description">The Description when an item is a folder</note>
			</trans-unit>
			<trans-unit id="LyfFoldersFilter" datatype="html">
				<source>Folders</source>
				<target>Dossiers</target>
				<note priority="1" from="description">The folders filter in Lyf</note>
			</trans-unit>
			<trans-unit id="LyfHeader" datatype="html">
				<source>LYF</source>
				<target>LYF</target>
				<note priority="1" from="description">Header for the Lyf Applications</note>
			</trans-unit>
			<trans-unit id="LyfInfoButton" datatype="html">
				<source>Info</source>
				<target>Info</target>
				<note priority="1" from="description">The info button in Lyf</note>
			</trans-unit>
			<trans-unit id="LyfNewFolderButton" datatype="html">
				<source>New Folder</source>
				<target>Nouveau dossier</target>
				<note priority="1" from="description">The new Folder button in Lyf</note>
			</trans-unit>
			<trans-unit id="LyfRenameFunction" datatype="html">
				<source>Rename</source>
				<target>Renommer</target>
				<note priority="1" from="description">The lyf Rename function</note>
			</trans-unit>
			<trans-unit id="LyfTypeFilterHeader" datatype="html">
				<source>Type</source>
				<target>Type</target>
				<note priority="1" from="description">Header for the Lyf Filter</note>
			</trans-unit>
			<trans-unit id="LyfUploadButton" datatype="html">
				<source>Upload</source>
				<target>Télécharger</target>
				<note priority="1" from="description">The button for lyf upload</note>
			</trans-unit>
			<trans-unit id="ManualLicense" datatype="html">
				<source>Manual License</source>
				<target>Licence manuelle</target>
				<note priority="1" from="description">Manual License</note>
			</trans-unit>
			<trans-unit id="MaxPayLoadSize" datatype="html">
				<source>Set Maximum Payload Size</source>
				<target>Taille de fichier maximale</target>
				<note priority="1" from="description">Set Maximum Payload Size</note>
			</trans-unit>
			<trans-unit id="ModifiedDate" datatype="html">
				<source>Modified</source>
				<target>Date de Modification</target>
				<note priority="1" from="description">Date Last Modified</note>
			</trans-unit>
			<trans-unit id="Modify" datatype="html">
				<source>Modify</source>
				<target>Modifier</target>
				<note priority="1" from="description">Modify</note>
			</trans-unit>
			<trans-unit id="ModifyGroup" datatype="html">
				<source>Modify Group</source>
				<target>Modifier le groupe</target>
				<note priority="1" from="description">Modify Group</note>
			</trans-unit>
			<trans-unit id="ModifyUser" datatype="html">
				<source>Modify User</source>
				<target>Modifier l'utilisateur</target>
				<note priority="1" from="description">Modify User</note>
			</trans-unit>
			<trans-unit id="MyAccountLink" datatype="html">
				<source>My Account</source>
				<target>Mon compte</target>
				<note priority="1" from="description">Link to the My Account Page</note>
			</trans-unit>
			<trans-unit id="Name" datatype="html">
				<source>Name</source>
				<target>Nom</target>
				<note priority="1" from="description">Name</note>
			</trans-unit>
			<trans-unit id="NetworkInterfaces" datatype="html">
				<source>Network Interfaces</source>
				<target>Interfaces réseau</target>
				<note priority="1" from="description">Network Interfaces</note>
			</trans-unit>
			<trans-unit id="NetworkSettings" datatype="html">
				<source>Network Settings</source>
				<target>Paramètres réseau</target>
				<note priority="1" from="description">Network Settings</note>
			</trans-unit>
			<trans-unit id="Newrows" datatype="html">
				<source>New rows</source>
				<target>Nouvelles lignes</target>
				<note priority="1" from="description">New rows</note>
			</trans-unit>
			<trans-unit id="Next" datatype="html">
				<source>Next</source>
				<target>Suivant</target>
				<note priority="1" from="description">Next</note>
			</trans-unit>
			<trans-unit id="OK" datatype="html">
				<source>OK</source>
				<target>OK</target>
				<note priority="1" from="description">OK</note>
			</trans-unit>
			<trans-unit id="Organization" datatype="html">
				<source>Organization</source>
				<target>Organisation</target>
				<note priority="1" from="description">Organization</note>
			</trans-unit>
			<trans-unit id="OrganizationUnit" datatype="html">
				<source>Organizational Unit</source>
				<target>Unité Organisationelle</target>
				<note priority="1" from="description">Organizational Unit</note>
			</trans-unit>
			<trans-unit id="OrganizationUnit/Department" datatype="html">
				<source>Organization Unit/Department</source>
				<target>Unité Organisationelle / Département</target>
				<note priority="1" from="description">Organization Unit/Department</note>
			</trans-unit>
			<trans-unit id="PasswordMismatch" datatype="html">
				<source>Password mismatch</source>
				<target>mots de passe non identiques</target>
				<note priority="1" from="description">Password mismatch</note>
			</trans-unit>
			<trans-unit id="Paste" datatype="html">
				<source>Paste</source>
				<target>Coller</target>
				<note priority="1" from="description">Paste</note>
			</trans-unit>
			<trans-unit id="Previous" datatype="html">
				<source>Previous</source>
				<target>Précédant</target>
				<note priority="1" from="description">Previous</note>
			</trans-unit>
			<trans-unit id="PrivacyPolicyLink" datatype="html">
				<source>Privacy Policy</source>
				<target>Politique de confidentialité</target>
				<note priority="1" from="description">Link to see the Privacy Policies</note>
			</trans-unit>
			<trans-unit id="PrivateKey" datatype="html">
				<source>Private Key</source>
				<target>Clé Privée</target>
				<note priority="1" from="description">Private Key</note>
			</trans-unit>
			<trans-unit id="ProceedUpgrade" datatype="html">
				<source>Yes, Iâm good to proceed</source>
				<target>Oui, Installer la mise à jour</target>
				<note priority="1" from="description">Yes, Iâm good to proceed</note>
			</trans-unit>
			<trans-unit id="ProgressUpgrade" datatype="html">
				<source>Upgrade in progress...Â Â </source>
				<target>Mise à jour en cours</target>
				<note priority="1" from="description">Upgrade in progress...Â Â </note>
			</trans-unit>
			<trans-unit id="PublicCertificate" datatype="html">
				<source>Generate Public Certificate</source>
				<target>Générer un certificat public</target>
				<note priority="1" from="description">Generate Public Certificate</note>
			</trans-unit>
			<trans-unit id="PublicKey" datatype="html">
				<source>Public Key</source>
				<target>Clé Privée</target>
				<note priority="1" from="description">Public Key</note>
			</trans-unit>
			<trans-unit id="PwdMismatch" datatype="html">
				<source> Password mismatch</source>
				<target>Mots de passe non identiques</target>
				<note priority="1" from="description"> Password mismatch</note>
			</trans-unit>
			<trans-unit id="Pwdmistmatch" datatype="html">
				<source> Password mismatch</source>
				<target>Mots de passe non identiques</target>
				<note priority="1" from="description"> Password mismatch</note>
			</trans-unit>
			<trans-unit id="Reboot" datatype="html">
				<source>Reboot</source>
				<target>Redémarrer</target>
				<note priority="1" from="description">Reboot</note>
			</trans-unit>
			<trans-unit id="ReleaseDate" datatype="html">
				<source>Release Date</source>
				<target>Date de livraison</target>
				<note priority="1" from="description">Release Date</note>
			</trans-unit>
			<trans-unit id="Rename" datatype="html">
				<source>Rename</source>
				<target>Renommer</target>
				<note priority="1" from="description">Rename</note>
			</trans-unit>
			<trans-unit id="Return" datatype="html">
				<source>Return</source>
				<target>Retour</target>
				<note priority="1" from="description">Return</note>
			</trans-unit>
			<trans-unit id="RootCertificate" datatype="html">
				<source>Root Certificate Authorities</source>
				<target>Certificats Racine</target>
				<note priority="1" from="description">Root Certificate Authorities</note>
			</trans-unit>
			<trans-unit id="Run" datatype="html">
				<source>Run</source>
				<target>Démarrer</target>
				<note priority="1" from="description">Run</note>
			</trans-unit>
			<trans-unit id="Save" datatype="html">
				<source>Save</source>
				<target>Sauver</target>
				<note priority="1" from="description">Save</note>
			</trans-unit>
			<trans-unit id="Set" datatype="html">
				<source>Set</source>
				<target>OK</target>
				<note priority="1" from="description">Set</note>
			</trans-unit>
			<trans-unit id="SetAsActive" datatype="html">
				<source> Set as active?</source>
				<target>Activer</target>
				<note priority="1" from="description"> Set as active?</note>
			</trans-unit>
			<trans-unit id="Setasactive?" datatype="html">
				<source> Set as active?</source>
				<target>Activer?</target>
				<note priority="1" from="description"> Set as active?</note>
			</trans-unit>
			<trans-unit id="Shutdown" datatype="html">
				<source>Shutdown</source>
				<target>Eteindre</target>
				<note priority="1" from="description">Shutdown</note>
			</trans-unit>
			<trans-unit id="ShutdownRestart" datatype="html">
				<source>Shutdown/Restart</source>
				<target>Arrêter / Redémarrer</target>
				<note priority="1" from="description">Shutdown/Restart</note>
			</trans-unit>
			<trans-unit id="Size" datatype="html">
				<source>Size</source>
				<target>Taille</target>
				<note priority="1" from="description">File Size</note>
			</trans-unit>
			<trans-unit id="StageOne" datatype="html">
				<source> Stage One</source>
				<target>Première Etape</target>
				<note priority="1" from="description"> Stage One</note>
			</trans-unit>
			<trans-unit id="StageTwo" datatype="html">
				<source> Stage Two</source>
				<target>Stage Deux</target>
				<note priority="1" from="description"> Stage Two</note>
			</trans-unit>
			<trans-unit id="StageTwoDesc" datatype="html">
				<source> Paste your Activation Key below </source>
				<target>Deuxième Etape</target>
				<note priority="1" from="description"> Paste your Activation Key below </note>
			</trans-unit>
			<trans-unit id="Start" datatype="html">
				<source>START</source>
				<target>DEMARRER</target>
				<note priority="1" from="description">START</note>
			</trans-unit>
			<trans-unit id="State" datatype="html">
				<source>State</source>
				<target>Etat</target>
				<note priority="1" from="description">State</note>
			</trans-unit>
			<trans-unit id="Stop" datatype="html">
				<source>STOP</source>
				<target>ARRETER</target>
				<note priority="1" from="description">STOP</note>
			</trans-unit>
			<trans-unit id="Submit" datatype="html">
				<source>SUBMIT</source>
				<target>Lancer</target>
				<note priority="1" from="description">SUBMIT</note>
			</trans-unit>
			<trans-unit id="System" datatype="html">
				<source>System </source>
				<target>Système</target>
				<note priority="1" from="description">System </note>
			</trans-unit>
			<trans-unit id="SystemOverview" datatype="html">
				<source>System Overview</source>
				<target>Aperçu système</target>
				<note priority="1" from="description">System Overview</note>
			</trans-unit>
			<trans-unit id="TakeBackUp" datatype="html">
				<source>TAKE BACKUP</source>
				<target>Sauvegarder</target>
				<note priority="1" from="description">TAKE BACKUP</note>
			</trans-unit>
			<trans-unit id="TestConnection" datatype="html">
				<source>Test Connection <x id="START_TAG_SPAN" ctype="x-span" equiv-text="<span>" /><x id="CLOSE_TAG_SPAN" ctype="x-span" equiv-text="</span>" /></source>
				<target>Test</target>
				<note priority="1" from="description">Test Connection <x id="START_TAG_SPAN" ctype="x-span" equiv-text="<span>" /><x id="CLOSE_TAG_SPAN" ctype="x-span" equiv-text="</span>" /></note>
			</trans-unit>
			<trans-unit id="ThirdPartyCertificate" datatype="html">
				<source>Third Party Certificates</source>
				<target>Certificat Tiers</target>
				<note priority="1" from="description">Third Party Certificates</note>
			</trans-unit>
			<trans-unit id="Troubleshooting" datatype="html">
				<source>Troubleshooting</source>
				<target>Dépannage</target>
				<note priority="1" from="description">Troubleshooting</note>
			</trans-unit>
			<trans-unit id="Type" datatype="html">
				<source>Type</source>
				<target>Type</target>
				<note priority="1" from="description">Type</note>
			</trans-unit>
			<trans-unit id="Update" datatype="html">
				<source>Update</source>
				<target>mise à jour</target>
				<note priority="1" from="description">Update</note>
			</trans-unit>
			<trans-unit id="UpdateAvailable" datatype="html">
				<source>Update Available</source>
				<target>Mise à jour disponible</target>
				<note priority="1" from="description">Update Available</note>
			</trans-unit>
			<trans-unit id="Upgrade" datatype="html">
				<source>Upgrade</source>
				<target>Mise à jour</target>
				<note priority="1" from="description">Upgrade</note>
			</trans-unit>
			<trans-unit id="UpgradeFile" datatype="html">
				<source>Upgrade File</source>
				<target>Fichier de mise à jour</target>
				<note priority="1" from="description">Upgrade File</note>
			</trans-unit>
			<trans-unit id="Upload" datatype="html">
				<source>Upload</source>
				<target>Télécharger</target>
				<note priority="1" from="description">Upload</note>
			</trans-unit>
			<trans-unit id="UploadCert" datatype="html">
				<source>Certificate to upload</source>
				<target>Certificat à télécharger</target>
				<note priority="1" from="description">Certificate to upload</note>
			</trans-unit>
			<trans-unit id="UploadCertMessage" datatype="html">
				<source>You can upload the certificate provided by your provider by pasting the value here</source>
				<target>Vous pouvez importer le certificat fourni par votre fournisseur en le copiant ici.</target>
				<note priority="1" from="description">You can upload the certificate provided by your provider by pasting the value here</note>
			</trans-unit>
			<trans-unit id="UploadNewCert" datatype="html">
				<source>Upload New Certificate</source>
				<target>Ajouter un Certificat racine</target>
				<note priority="1" from="description">Upload New Certificate</note>
			</trans-unit>
			<trans-unit id="UploadNewCertificate" datatype="html">
				<source>Upload New Certificate</source>
				<target>Télécharger un nouveau certificat</target>
				<note priority="1" from="description">Upload New Certificate</note>
			</trans-unit>
			<trans-unit id="User" datatype="html">
				<source>User</source>
				<target>Utilisateur</target>
				<note priority="1" from="description">User</note>
			</trans-unit>
			<trans-unit id="UserLogout" datatype="html">
				<source>Logout</source>
				<target>Déconnexion</target>
				<note priority="1" from="description">Logout Button for the User</note>
			</trans-unit>
			<trans-unit id="Username" datatype="html">
				<source>Username</source>
				<target>Nom d'utilisateur</target>
				<note priority="1" from="description">Username</note>
			</trans-unit>
			<trans-unit id="UsernameError" datatype="html">
				<source> Username is required</source>
				<target>Erreur de nom d'utilisateur</target>
				<note priority="1" from="description"> Username is required</note>
			</trans-unit>
			<trans-unit id="UsernameRequired" datatype="html">
				<source> Username is required</source>
				<target>Nom d'utilisateur requis</target>
				<note priority="1" from="description"> Username is required</note>
			</trans-unit>
			<trans-unit id="Users" datatype="html">
				<source>Users</source>
				<target>Utilisateurs</target>
				<note priority="1" from="description">Users</note>
			</trans-unit>
			<trans-unit id="ViewCertificate" datatype="html">
				<source>View Certificate</source>
				<target>Voir le certificat</target>
				<note priority="1" from="description">View Certificate</note>
			</trans-unit>
			<trans-unit id="WelcomeBackExclamationMark" datatype="html">
				<source>Welcome Back!</source>
				<target>Bienvenue!</target>
				<note priority="1" from="description">Welcome Back with exclamation mark</note>
			</trans-unit>
			<trans-unit id="Workspace" datatype="html">
				<source>Workspace</source>
				<target>Espace de Travail</target>
				<note priority="1" from="description">Workspace</note>
			</trans-unit>
			<trans-unit id="WorkspaceNoApplicationsError" datatype="html">
				<source>User has no assigned applications! Please contact your system administrator.</source>
				<target>Aucune application allouée a l'utilisateur. Veuillez contacter votre administrateur.</target>
				<note priority="1" from="description">User has no assigned applications! Please contact your system administrator.</note>
			</trans-unit>
		</body>
	</file>
</xliff>`;