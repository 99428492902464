import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { AzureService } from './azure.service';
import { MSALInstanceFactory } from './msalfactorybuilder';
import { ReplyAuthenticateUser, RequestAuthenticateUser } from './login/authMessages';
import { RpcService } from './rpc.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  requestAuthenticateUser: RequestAuthenticateUser;
  replyAuthenticateUserRes: ReplyAuthenticateUser;

  ngOnInit(): void {
    this.msalService.instance.enableAccountStorageEvents()

    this.azureService.loadAppConfig().then(() => {
      this.msalService.instance = MSALInstanceFactory(this.azureService)
    })

    this.msalBroadcastService.msalSubject$
      .subscribe((msg: EventMessage) => {
        if (msg.eventType === EventType.INITIALIZE_START) {

        }
        if (msg.eventType === EventType.LOGIN_SUCCESS
          ||
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          ||
          msg.eventType === EventType.SSO_SILENT_SUCCESS
        ) {
          this.router.navigate(['redirect']);
        }
      });
  }


  constructor(
    private msalService: MsalService, private msalBroadcastService: MsalBroadcastService, private router: Router,
    private azureService: AzureService, private service: RpcService) {
    this.requestAuthenticateUser = new RequestAuthenticateUser();

    if (localStorage.getItem('clientId') != null) {

      if (this.msalService.instance.getActiveAccount()) {
        this.router.navigate(['redirect']);
      }
    }
  }
}