<header>
        <table class="tableheader">
            <tr style="background-color:#2C3643"> 
                <td style="width:85px;">
                    <img width="50" height="32" src="assets/images/netconnectlogo.png" style="padding: 5px; margin: 0 auto; display: block">
                </td>
                <td i18n="@@WelcomeBackExclamationMark" style="width:26%">
                    NetConnect
                </td>
                <td>
                <!--<button mat-button style="float: right; color: white;" [matMenuTriggerFor]="language">Language</button>-->
                    
                    <mat-menu #language="matMenu">
                        <button mat-menu-item (click)="selectLanguage('en-US')">EN</button>
                        <!-- <button mat-menu-item (click)="selectLanguage('de-DE')">DE</button> -->
                        <button mat-menu-item (click)="selectLanguage('fr-FR')">FR</button>
                    </mat-menu>
                </td>
            </tr>
        </table>
    </header>
<div class="container licensebody" style="overflow:auto;">
    <div class="license-box">
        <div class="license-logo">
            <img style="width: 50px" [src]="'assets/images/netconnectlogoblue.png'" alt="NetConnect">
        </div>
        <div class="license-logo">
            <span i18n="@@LicenseAgreement">License Agreement</span><br>
        </div>
        <div class="license-box-body mat-elevation-z2">
            <p i18n="@@LicenseIntroduction">Please read the following License Agreement. You must accept the terms
                    of this agreement before continuing the installation.</p>
            <br><br>
            <textarea readonly rows="0" height="100" class="form-control bigtextbox" #textarea [(ngModel)]="eula"></textarea>
            <br>
            <label class="radio-inline">
            <input i18n="@@LicenseAccept" type="radio" name="agreement" [(ngModel)]="eulaagree" [value]="true" (change)="change(eulaagree)" required>I accept the agreement</label>
            <label i18n="@@LicenseRefuse" class="radio-inline"><input type="radio" name="agreement" [(ngModel)]="eulaagree" [value]="false" (change)="change(eulaagree)" required>I do not accept the agreement</label>
            <br><br>
            
            <div *ngIf="showError" class="alert alert-danger alert-dismissable">
                    <strong i18n="@@LicensePleaseAccept">Please accept the agreement to continue</strong>
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
            </div>
            <div style="text-align:right">
                <button type="button" i18n="@@AdminSubmitUppercase" [disabled]="showError" class="btn" (click)="redirectToCreateAdminUser()">NEXT</button>
            </div>
        </div>
    </div>
</div>

