import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrCodeData } from './DialogData';

@Component({
    selector: 'qrcode-modal',
    templateUrl: './qrCodeModal.component.html'
})
export class QrCodeModal {

    constructor(
        public dialogRef: MatDialogRef<QrCodeData>,
        @Inject(MAT_DIALOG_DATA) public data: QrCodeData) { }

    onClick(value: string): void {
        this.dialogRef.close(value);
      }




}