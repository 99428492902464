import { Component,HostListener } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RpcService } from '../rpc.service';

@Component({
  selector: 'app-root',
  templateUrl: './tablet.component.html',
  styleUrls: ['./tablet.component.css'],
  providers :[]
  
})
export class TabletComponent{
  currentLocale : string;
    type :string;
  constructor(private rpcService: RpcService) {
    this.isMobile();
  }


  ngOnInit() {
    
  }

  isMobile() : void{
    if(navigator.userAgent.match(/Android/i)){
      if(navigator.userAgent.match(/mobile/i)){
       this.type= "mobile";
      }else {
       this.type ="tablet";
      }  
    }else if(navigator.userAgent.match(/iPhone/i)){
        this.type= "iPhone";
    }else if(navigator.userAgent.match(/iPad/i)){
        this.type = "iPad";
    }
    
   }

   getMargin() {
       if(this.type == "mobile" || this.type == "iPhone"){
           return "0 auto";
       }
   }
}