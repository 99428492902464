<h1 class = "mbr-section-title pb-3 mbr-white mbr-bold mbr-fonts-style display-1 align-center" align="center">Multi-Factor Authentication</h1>
<div class="mbr-fonts-style display-5">
    Please download the Google Authenticator mobile app to your mobile phone and scan the below code to proceed.<br>
Once you have successfully scanned the code, click ‘OK’.<br>
If you’re unable to scan the code at this time, click cancel. Do not click ‘OK’, doing so will prevent future access.<br>
Please contact you system administrator if you require assistance.
</div>
<div mat-dialog-content>
 <div style="text-align:center;">
    <img src="data:image/png;base64,{{ data.qrCodeData }}"> 
 </div>
 <div style="text-align:center;">
    Manual Entry Key: {{ data.key }}
</div>

</div>

<div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" mat-button (click)="onClick('OK')">Ok</button>
    <button mat-raised-button color="primary" mat-button (click)="onClick('Cancel')" >Cancel</button>
</div>

