import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { LoginFieldBase } from './loginfield-base';

@Injectable()
export class LoginFormControlService {
  constructor() { }

  toFormGroup(loginFields: LoginFieldBase<any>[] ) {
    let group: any = {};

    loginFields.forEach(loginField => {
      group[loginField.name] = new FormControl();
    });
    return new FormGroup(group);
  }
}