import { TRANSLATION_deDE } from './messages.deDE';
import { TRANSLATION_enUS } from './messages.enUS';
import { TRANSLATION_frFR } from './messages.frFR';

export class LocaleHelper {
    static US_LOCALE: string = 'en-US';
    static DE_LOCALE: string = 'de-DE';
    static FR_LOCALE: string = 'fr-FR';

    static US_IMAGE_ICON: string = 'assets/images/flags/flags_iso/48/au.png';
    static DE_IMAGE_ICON: string = 'assets/images/flags/flags_iso/48/de.png';
    static FR_IMAGE_ICON: string = 'assets/images/flags/flags_iso/48/fr.png';

    static US_TRANSLATION_FILE = TRANSLATION_enUS;
    static DE_TRANSLATION_FILE = TRANSLATION_deDE;
    static FR_TRANSLATION_FILE = TRANSLATION_frFR;
}