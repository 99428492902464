import { MessageReply, MessageRequest } from '../messages';
import {
  AppInstanceHeading,
  AppTypeField,
  ExternalStorageInfo
} from './workspace.models';

//getUserInfo ++
export class RequestGetUserInfo extends MessageRequest {
    function = "getUserInfo";
    service = "Authentication";
    token = localStorage.getItem('userToken');
}

export class ReplyGetUserInfo extends MessageReply {
    data: ReplyGetUserInfo.Data;
}

export namespace ReplyGetUserInfo {
    export class Data {
        user: AuthTypeUser;
    }
    export class AuthTypeUser {
        user_heading: AuthTypeUserHeading;
        user_fields: Array<FieldIdValue>
    }
    export class AuthTypeUserHeading {
        user_id: number;
        user_name: string;
        auth_instance_id: number;
    }
    export class FieldIdValue {
        id: number;
        value: string;
    }
}
//getUserInfo --


//getUserApps ++
export class RequestGetUserApps extends MessageRequest {
    function = "getUserApps";
    service = "Application";
    token = localStorage.getItem('userToken');
}

export class ReplyGetUserApps extends MessageReply {
    data: ReplyGetUserApps.Data;
}

export namespace ReplyGetUserApps {
    export class Data {
        appInfo: Map<number, AppInstanceHeading>;
        accessInfo: Map<number, AppAccess>;
    }
    export class AppInstanceHeading {
        app_instance_id: number;
        app_type_id: number;
        app_type_name: string;
        app_instance_name: string;
        app_instance_descriptions: string;
        app_instance_image: string;
    }
    export class AppAccess {
        app_access_type: string;
        app_access_string: string;
    }
}
//getUserApps --

//logout ++
export class RequestLogout extends MessageRequest {
    function = "logout";
    service = "Authentication";
    token = localStorage.getItem('userToken');
    data: RequestLogout.Data = new RequestLogout.Data();
}

export namespace RequestLogout {
    export class Data {
        userId: number;
    }
}

export class ReplyLogout extends MessageReply {
    data: ReplyLogout.Data;
}
export namespace ReplyLogout {
    export class Data {
        valid: boolean;
    }
}
//logout --


//CheckTokenTimeout ++
export class RequestCheckTokenTimeout extends MessageRequest {
    function = "checkTokenTimeout";
    service = "Authentication";
    token = localStorage.getItem('userToken');
}

export class ReplyCheckTokenTimeout extends MessageReply {
    data: ReplyCheckTokenTimeout.Data;
}
export namespace ReplyCheckTokenTimeout {
    export class Data {
        tokenTimedOut: boolean;
    }
}
//CheckTokenTimeout --

//CheckTokenActivity ++
export class RequestCheckTokenActivity extends MessageRequest {
    function = "checkTokenActivity";
    service = "Authentication";
    token = localStorage.getItem('userToken');
}

export class ReplyCheckTokenActivity extends MessageReply {
    data: ReplyCheckTokenActivity.Data;
}
export namespace ReplyCheckTokenActivity {
    export class Data {
        tokenChanged: boolean;
        toOS: String;
        toBrowser: String;
    }
}
//CheckTokenActivity --

//CheckAndGetConcurrentLicense  ++
export class RequestCheckAndGetConcurrentLicense extends MessageRequest {
    function = "CheckAndGetConcurrentLicense";
    service = "Authorization";
    token = localStorage.getItem('userToken');
    data: RequestCheckAndGetConcurrentLicense.Data = new RequestCheckAndGetConcurrentLicense.Data();
}

export namespace RequestCheckAndGetConcurrentLicense  {
    export class Data {
        groupId: number;
        groupName: string;
        userId: number;
    }
}

export class ReplyCheckAndGetConcurrentLicense extends MessageReply {
    data: ReplyCheckAndGetConcurrentLicense.Data;
}
export namespace ReplyCheckAndGetConcurrentLicense  {
    export class Data {
        valid: boolean;
    }
}
//CheckAndGetConcurrentLicense  --

//ReturnConcurrentLicense  ++
export class RequestReturnConcurrentLicense extends MessageRequest {
    function = "ReturnConcurrentLicense";
    service = "Authorization";
    token = localStorage.getItem('userToken');
    data: RequestReturnConcurrentLicense.Data = new RequestReturnConcurrentLicense.Data();
}

export namespace RequestReturnConcurrentLicense  {
    export class Data {
        groupId: number;
        groupName: string;
        appId: number;
        userId: number;
    }
}

export class ReplyReturnConcurrentLicense extends MessageReply {
    data: ReplyReturnConcurrentLicense.Data;
}
export namespace ReplyReturnConcurrentLicense  {
    export class Data {
        valid: boolean;
    }
}
//ReturnConcurrentLicense  --

//ValidateLicenseForApp  ++
export class RequestValidateLicenseForApp extends MessageRequest {
    function = "ValidateLicenseForApp";
    service = "Authorization";
    token = localStorage.getItem('userToken');
    data: RequestValidateLicenseForApp.Data = new RequestValidateLicenseForApp.Data();
}

export namespace RequestValidateLicenseForApp  {
    export class Data {
        userId: number;
        appId: number;
        appName: string;
    }
}

export class ReplyValidateLicenseForApp extends MessageReply {
    data: ReplyValidateLicenseForApp.Data;
}
export namespace ReplyValidateLicenseForApp {
    export class Data {
        valid: boolean;
    }
}
//ValidateLicenseForApp  --

//Validate uid start
export class RequestGetXYFromUID extends MessageRequest{
  function = 'GetXYFromUID';
  service = 'Authorization';
  data: RequestGetXYFromUID.Data = new RequestGetXYFromUID.Data();

}
export namespace RequestGetXYFromUID {
  export class Data {
      uid: string;
  }
}

export class ReplyGetXYFromUID extends MessageReply{
  data: ReplyGetXYFromUID.Data;

}
export namespace ReplyGetXYFromUID{
  export class Data{
    width: number;
    height: number;
  }
}

//Validate uid end

/**
 * getAppInstanceFieldsAndValues
 */
export class RequestGetAppInstanceFieldsAndValues extends MessageRequest {
  function = "getAppInstanceFieldsAndValues";
  service = "Application";
  token = localStorage.getItem('userToken');
  data: RequestGetAppInstanceFieldsAndValues.Data = new RequestGetAppInstanceFieldsAndValues.Data();
}

export namespace RequestGetAppInstanceFieldsAndValues {
  export class Data {
      app_type_id: number;
      app_instance_id: number;
  }
}
export class ReplyGetAppInstanceFieldsAndValues extends MessageReply {
  data: ReplyGetAppInstanceFieldsAndValues.Data;
}

export namespace ReplyGetAppInstanceFieldsAndValues {
  export class Data {
      app_type_fields: Array<AppTypeField>;
      app: AppInstanceHeading;
  }
}

// *************************************** 
// GetRemoteStorageInfo
// *************************************** 

export class RequestGetExternalStorageSettings extends MessageRequest {
    function = "GetExternalStorageSettings";
    service = "Admin";
    token = localStorage.getItem('userToken');

    data: RequestGetExternalStorageSettings.Data = new RequestGetExternalStorageSettings.Data();
}

export namespace RequestGetExternalStorageSettings {
    export class Data {
        public id: number;
        public type: string;
    }
}


export class ReplyGetExternalStorageSettings extends MessageReply {

    data: ReplyGetExternalStorageSettings.Data = new ReplyGetExternalStorageSettings.Data();
}

export namespace ReplyGetExternalStorageSettings {
    export class Data {
       info: Array<ExternalStorageInfo>;

    }
}
