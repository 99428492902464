<div class="container loginbody">
    <div class="login-box">
        <div class="login-logo">
            <img class="login-logo-image" src="assets/images/netconnectlogoblue.png" alt="NetConnect">
        </div>
        <div class="login-logo">
            <span i18n="@@WelcomeBackExclamationMark">NetConnect</span>
        </div>

        <mat-card (mouseenter)='mouseenter($event)' (mouseleave)="mouseleave($event)" class="login-box-body">
            <form #loginForm="ngForm" class="example-form" [formGroup]="form" (ngSubmit)="onLogin(loginForm)">
                <mat-form-field class="login-input" *ngFor="let loginField of loginFields">
                    <input matInput class="inputField" [formControlName]="loginField.name" id="{{loginField.id}}"
                        name="{{loginField.name}}" type="{{loginField.type}}" placeholder="{{loginField.label}}"
                        #dynamicInput>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit" class="btn-block" name="submit" id="submit"
                    [disabled]="!form.valid">
                    <span [hidden]="!showLoading" class="spinner">
                        <i class="glyphicon glyphicon-refresh spinning"></i>
                    </span>
                    <span i18n="@@LoginUppercase">Login</span>
                </button>
                <div class="alert alert-danger error" *ngIf="wrongCredentials">
                    <strong i18n="@@LoginIncorrectCredentialsBold">Username or password wrong!</strong>
                    <p i18n="@@LoginIncorrectCredentials">Please re-enter your Credentials.</p>
                </div>
                <div class="alert alert-danger error" *ngIf="showErr">
                    <p>{{errorMsg}}</p>
                </div>
            </form>
        </mat-card>
    </div>
    <div class="image-container">
        <img src="/Admin/uploads/logo.png" onerror="this.onerror=null; this.remove();">
    </div>
</div>