
<div class ="topcorner">

</div>
<table width="100%" > <!--[style.margin]="getMargin()"-->
  <tr>
    <td> <!--style="padding-top:20%"-->
            <img src = "assets/images/nn_icon.png" align="middle" style="display:block;margin-left:auto;margin-right:auto;margin-bottom:20px;width:100px">   
    </td>
  </tr>
  <tr>
      <td>
            <img src = "assets/images/netconnect_navyblue.png" align="middle" style="display:block;margin-left:auto;margin-right:auto;margin-bottom:20px">
      </td>
  </tr>
  <tr>
    <td  style="background:#05C1FF;height:150px;vertical-align:middle;">
      <table align = "center" style="margin-left:auto;margin-right:auto;height:100%;margin-top:0">
          <tr>     
            <td>
              <p style="color:#ffffff; font-size:50px;font-family:Arial;text-align:center">NetConnect X has arrived
              </p>
            </td>  
          </tr>   
          <tr>
            <td>
              <p style="color:#ffffff; font-size:30px;font-family:Arial;text-align:center">Download from the play store today
              </p>
            </td>
          </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td>
    </td>
  </tr>
</table>

<div style="display: inline;margin:50px">

  <div style="text-align: center">
    <!--  <a [href]="market://details?id=com.northbridge.nextgen"> --> <a [href]="type == 'mobile' || type == 'tablet' ? 'market://details?id=com.northbridge.nextgen' : 'https://appsto.re/au/w5wQ5.i'">
        <img [src]="type == 'mobile' || type == 'tablet' ? 'assets/images/googleplaybadge.png' : 'assets/images/appstoredownload.png'" width="30%" height="50px" style="margin-right:40px;">
    </a>

     <a href="javascript:document.location.href='/';"><img src="assets/images/webversion.png" width="30%" height="50px" style="margin-left:40px;"></a>
  </div>

</div>

