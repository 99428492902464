
import { VmonData } from './DialogData';
import { Component, Inject } from '@angular/core';
import { RpcService } from '../../rpc.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestGetXYFromUID, ReplyGetXYFromUID } from '../workspaceMessages';

@Component({
  selector: 'app-rdp-launch-dialog',
  templateUrl: './rdp-launch-dialog.component.html',
  styleUrls: ['./rdp-launch-dialog.component.css'],
  providers: [RpcService]
})
export class RdpLaunchDialogComponent {
  errorMessage: string;
  requestGetXYFromUID: RequestGetXYFromUID;
  replyGetXYFromUID: ReplyGetXYFromUID;
  showSessionRecording: boolean;

  constructor(
    public dialogRef: MatDialogRef<VmonData>, private rpcService: RpcService,
    @Inject(MAT_DIALOG_DATA) public data: VmonData) {
    this.requestGetXYFromUID = new RequestGetXYFromUID();



    this.showSessionRecording = data.showSessionRecording;

    //console.log("Session Recording Value: " + this.showSessionRecording);


  }

  onClickSingleMonitor() {
    this.dialogRef.close({ type: 'single', uuid: null });
  
  }

  onClickMultiMonitor() {

    this.requestGetXYFromUID.data.uid = this.data.uuid;

    this.rpcService.postRequest(this.requestGetXYFromUID).subscribe(respGetXYFromUID => {
      this.replyGetXYFromUID = respGetXYFromUID;
      if (this.replyGetXYFromUID.data.width < 0) {
       
      } else {
        this.dialogRef.close({ type: 'multi', uuid: this.data.uuid });
      }
    });
  }

  onClickPasteAndLaunch() {
    let clip: any;
    clip = window.navigator;

    clip.clipboard.readText().then(data => {
     
      this.data.uuid = data.replace(/[\n\r]/g, '');
      this.requestGetXYFromUID.data.uid = this.data.uuid;

      this.rpcService.postRequest(this.requestGetXYFromUID).subscribe(respGetXYFromUID => {
        this.replyGetXYFromUID = respGetXYFromUID;
        if (this.replyGetXYFromUID.data.width < 0) {
         
        } else {
          this.dialogRef.close({ type: 'multi', uuid: this.data.uuid });
        }
      });
    })
    .catch(err => {
      console.error('Failed to read clipboard contents: ', err);
    });



  }




}
