<div class="container loginbody">
    <div class="login-box">
        <div class="login-logo">
            <img class="login-logo-image" src="assets/images/netconnectlogoblue.png" alt="NetConnect">
        </div>
        <div class="login-logo">
            <button mat-raised-button color="primary" type="submit" class="btn-block" name="submit" id="submit" (click)="loginAsAdminUser()">
                
                <span i18n="@@LoginAsAdminUser">Login as master admin user</span>
            </button>
            <button mat-raised-button color="primary" type="submit" class="btn-block" name="submit" id="submit" (click)="loginAsAzureUser()">
                
                <span i18n="@@LoginAsAzureUser">Login as azure user</span>
            </button>
        </div>  
    </div>
</div>