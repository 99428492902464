import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkspaceComponent } from './workspace/workspace.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { FirstTimeAccessComponent } from './firsttimeaccess/firsttimeaccess.component';
import { CreateAdminComponent } from './firsttimeaccess/createAdmin/createadmin.component';
import { EulaComponent } from './firsttimeaccess/eula/eula.component'
import { MobileComponent } from './mobile/mobile.component'
import { TabletComponent } from './tablet/tablet.component';
import { RedirectComponent } from './redirecting/redirecting.component';
import { AzureLoginTypeComponent } from './azurelogintype/azurelogintype.component';
import { MasterAdminLoginComponent } from './masteradminlogin/masteradminlogin.component';

const routes: Routes = [
    { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
    //  { path: 'firstTimeAccess', component: FirstTimeAccessComponent },
    { path: 'eula', component: EulaComponent },
    { path: 'createAdminUser', component: CreateAdminComponent },
    { path: 'login', component: LoginComponent },
    { path: 'workspace', component: WorkspaceComponent },
    { path: 'tablet', component: TabletComponent },
    { path: 'mobile', component: MobileComponent },
    { path: 'tablet', component: TabletComponent },
    { path: 'mobile', component: MobileComponent },
    //  {path:'logintype',component:AzureLoginTypeComponent},
    { path: 'masteradminlogin', component: MasterAdminLoginComponent },
    //{path: ':id_token', component:RedirectComponent},
    { path: 'redirect', component: RedirectComponent },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}

export const routingComponents = [LoginComponent,
    WorkspaceComponent, FirstTimeAccessComponent,
    EulaComponent, CreateAdminComponent, MobileComponent, TabletComponent, RedirectComponent,
    AzureLoginTypeComponent, MasterAdminLoginComponent]