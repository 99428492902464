import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './azurelogintype.component.html',
  styleUrls: ['./azurelogintype.component.css'],
  providers: []

})
export class AzureLoginTypeComponent {


  constructor(private router: Router, private authService: MsalService) {

  }

  loginAsAdminUser() {
    this.router.navigate(['/masteradminlogin']);
  }

  loginAsAzureUser() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

}